import { Button, Dialog, DialogContent } from '@mui/material';
import React, { useContext, useState } from 'react'
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import useDidMountEffect from '../../hooks/useDidMountEffect';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Eventregister from '../Media-module/Media-layout-page/Event-Page/Eventregister';
import DataContext from '../../context/DataContext';

const CustomCalendar = ({ value, name,handleViewEventDetails }) => {
    const { fullScreen } = useContext(DataContext);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [daysInMonth, setDaysInMonth] = useState([]);
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    useDidMountEffect(() => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const daysArray = [];

        for (
            let i = 0;
            i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1);
            i++
        ) {
            daysArray.push(null);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }

        setDaysInMonth(daysArray);
    }, [currentDate]);

    const changeMonth = (offset) => {
        const newDate = new Date(
            currentDate.setMonth(currentDate.getMonth() + offset)
        );
        setCurrentDate(newDate);
    };
    
    return (
        <>

            {/* <div className="calendar-controls">
                <Button className="year-btn" onClick={() => changeMonth(-1)}>
                    <FaChevronLeft />
                </Button>
                <span>
                    {currentDate.toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                    })}
                </span>
                <Button className="year-btn" onClick={() => changeMonth(1)}>
                    <FaChevronRight />
                </Button>
            </div> */}
            {
                value.length > 0 ? (
                    <div className="calendar">
                        <div className="calendar-header">
                            {daysOfWeek.map((day) => (
                                <div key={day} className="calendar-header-day">
                                    {day}
                                </div>
                            ))}
                        </div>
                        <div className="calendar-body">
                            {daysInMonth.map((day, index) => {
                                const dateEvents = value.find((d) => Number(d.date) === day);
                                return (
                                    <div key={index} className={`calendar-day calendar-day-${index}`}>
                                        {day && (
                                            <>
                                                <div className='active_events'>
                                                    {dateEvents &&
                                                        dateEvents.events.map((event, eventIndex) => (
                                                            <div
                                                                key={eventIndex}
                                                                className={`calendar-event calendar-event-active`}
                                                                onClick={() => handleViewEventDetails(event.id)}            >
                                                                <div className="event-title">{event.title}</div>
                                                                {event.location && (
                                                                    <div className="event-locations">{event.location}</div>
                                                                )}
                                                                <div className="event-times">{event.time}</div>
                                                            </div>
                                                        ))}
                                                </div>
                                                <div className="calendar-date">{day}</div>
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                ) : (
                    <div className='text-center nodatacss_admin'>
                        <h5 className='mb-4'>{name} data not found</h5>
                        <div>
                            <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
                        </div>
                        <div className='mt-5'>
                            <h3 className='title_p opa'>
                                StartupTN 3.0
                            </h3>
                        </div>
                    </div>
                )
            }           
        </>
    )
}

export default CustomCalendar
import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Avatar, Box, Dialog, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { Margin } from "@mui/icons-material";
import SideMenu from "./SideMenu";
import StartupMenu from "./StartupMenu";
import ebablerMenu from "./StartupMenu";
import EnablerMenus from "./EnablerMenus";
import Changepassword from "../../common-pages/Changepassword";


const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Navbar = () => {
  const { navigator, setUserProfileId, setUserProfileParsona,removeItem, checkSessionTimout } = useContext(DataContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  let continueWith = localStorage.getItem('continueWith')
  let loignType
  useDidMountEffect(() => {
    loignType = localStorage.getItem('type')
    checkSessionTimout()
    const handleScroll = () => {
      if (window.scrollY > 50) {  // Adjust the scroll position value as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  let logo = localStorage.getItem('logo')
  let name=localStorage.getItem('name')
  let token = localStorage.getItem("jwttoken")
  let idVal = localStorage.getItem("idValue")
  // useDidMountEffect(() => {
  //   if (token !== null && logo === null) {
  //     // getApi()
  //   }
  // }, [])
  // const [startupName, setStartupName] = useState('')
  // const getApi = () => {
  //   var id = localStorage.getItem("id");
  //   var type = localStorage.getItem('type')
  //   apiService(userprofile/get?persona=${type}&userid=${id}, "", "get")
  //     .then((result) => {
  //       if (result && result.data) {
  //         var data = result.data
  //         if (data.imageName) {
  //           localStorage.setItem('logo', data.imageName)
  //         } else {
  //           setStartupName(data.name)
  //         }

  //       }

  //     }).catch((err) => {

  //     });
  // }
  const logoutBtn = () => {
    handleCloseUserMenu()
    localStorage.clear()
    navigator('/')
  }
  const handleProfileType = () => {
    navigator('/profile')
    removeItem()
  }
  const profilePage = () => {
    setUserProfileId(0)
    setUserProfileParsona('')
    let type = localStorage.getItem('profile')
    var name = localStorage.getItem('type')
    if (name === 'STARTUP') {
      if (type === 'true') {
        navigator('/startup/profile')
      } else if (type === 'false') {
        navigator('/startups/basicinfo')
      }
    } else if (name === 'ASPIRANT') {
      if (type === 'true') {
        navigator('/aspirant/profile')
      } else if (type === 'false') {
        navigator('/aspirants/basicinfo')
      }
    } else if (name === 'MENTOR') {
      if (type === 'true') {
        navigator('/mentor/profile')

      } else if (type === 'false') {
        navigator('/mentors/basicinfo')
      }
    } else if (name === 'INNOVATOR') {
      if (type === 'true') {
        navigator('/innovator/profile')
      } else if (type === 'false') {
        navigator('/innovators/basicinfo')
      }

    } else if (name === 'INVESTOR') {
      if (type === 'true') {
        navigator('/investor/profile')

      } else if (type === 'false') {
        navigator('/investor/basicinfo')
      }

    } else if (name === 'STARTUP-ECO') {
      if (type === 'true') {
        navigator('/ecosystem/profile')

      } else if (type === 'false') {
        navigator('/ecosystem/basicinfo')
      }

    } else if (name === 'STARTUPS-SERVPROV') {
      if (type === 'true') {
        navigator('/service-provider/profile')

      } else if (type === 'false') {
        navigator('/service-provider/basicinfo')
      }

    } else if (name === 'NA'){
      navigator(`letsstart/${btoa(idVal)}`)
    } else {
      navigator('/admin')
    }
    removeItem()
  }
  const adminDashboardPage = () => {
    navigator('/admin')
    removeItem()
  }
  const [openStartupMenu, setOpenStartupMenu] = useState(false)
  const handleStartupMenu = () => {

    setOpenStartupMenu(!openStartupMenu)
    setEnablerMenu(false)

  }
  const [openEnablerMenu, setEnablerMenu] = useState(false)
  const handleEnablerMenu = () => {
    setOpenStartupMenu(false)
    setEnablerMenu(!openEnablerMenu)

  }
  const [isActive, setIsActive] = useState(false);
  const [anchorElStartupMenu, setAnchorElStartupMenu] = React.useState(false);
  const handleOpenStartupMenu = () => {
    setAnchorElStartupMenu(!anchorElStartupMenu);
    setAnchorElEnablersMenu(null);
    setIsActive(!isActive);
    setIsActive2(false);
  };
  const handleCloseStartupMenu = () => {
    setAnchorElStartupMenu(null);
    setIsActive(false);
    
  };
  const [isActive2, setIsActive2] = useState(false);
  const [anchorElEnablersMenu, setAnchorElEnablersMenu] = React.useState(false);
  const handleOpenEnablersMenu = () => {
    setAnchorElEnablersMenu(!anchorElEnablersMenu);
    setAnchorElStartupMenu(null);
    setIsActive2(!isActive2);
    setIsActive(false);
  };
  const handleCloseEnablersMenu = () => {
    setAnchorElEnablersMenu(null);
    setIsActive2(false);
   
  };
  const [openPassword, setOpenPassword] = useState(false);
  const handlePasswordOpen = () => {
    setOpenPassword(true);
  }
  const handlePasswordClose = () => {
    setOpenPassword(false);
  }
 
  return (
    <>
      <div className={`${isScrolled ? 'aleart-msg set-bg' : 'aleart-msg1 set-bg'}`} >
        <span className="sys_view">You are using a beta version of our website. We are still refining features. If you experience any problems or have suggestions, please contact us at tech@startuptn.in / +91 8610331001




</span>
        {/* <Link to={'/login'} className={`${isScrolled ? 'text-primary' : 'marquee_a'}`}>raise a support ticket.</Link> */}
        <span className="mob_view">This is a beta version, if you experience any problems please contact us at tech@startuptn.in / +91 8610331001
          {/* <Link to={'/login'} className={`${isScrolled ? 'text-primary' : 'marquee_a'}`}>raise a support ticket.</Link> */}
        </span>

      </div>
      <div className={`${isScrolled ? 'scrolled fixed-tops' : 'fixed-top'}`} >
        {/* <marquee className={''}> */}

        {/* </marquee> */}
        <div className={token !== null ? 'container' : 'container-fluid margin-css'}>
          <nav className="navbar navbar-expand-lg navbar-light bg-lights nav-pads">
            {
              token === null && (
                <SideMenu />
              )
            }
            <Link className="navbar-brand nav-log-res1" to="/" onClick={removeItem}>
              <img src="/images/blue horizontal.png" alt="" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">


              <Link to="/" onClick={removeItem}>
                <img
                  className="img-fluid nav-logo-profile"
                  src="/images/blue horizontal.png"
                  alt=""
                />
              </Link>

              <ul className="navbar-nav nav-items m-auto">
                {/* Dropdown 1 */}
                <li className="nav-item dropdown mr-1">
                  <Link
                    className={`nav-link menues dropdown-toggle ${isActive ? 'active' : ''}`}
                    role="button"
                    onClick={handleOpenStartupMenu}
                    style={{ color: isActive ? 'blue' : '' }}
                  >
                    For Startups
                  </Link>

                </li>
                {/* Dropdown 2 */}
                <li className="nav-item dropdown">
                  <Link
                    className={`nav-link menues dropdown-toggle ${isActive2 ? 'active' : ''}`}
                    role="button"
                    // aria-expanded="false"
                    onClick={handleOpenEnablersMenu}
                    style={{ color: isActive2 ? 'blue' : '' }}
                  >
                    For Enablers
                  </Link>
                  {/* <div className="dropdown-menu">
                    <Link className="dropdown-item" >Action</Link>
                    <Link className="dropdown-item" >Another action</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" >Something else here</Link>
                  </div> */}
                </li>
                <li className="nav-item mr-1">
                  <NavLink className="nav-link menues" to={'/events/list'} onClick={removeItem}>Events & Media</NavLink>
                </li>
                <li className="nav-item mr-1">
                  <NavLink className="nav-link menues" to={'/ecosystem-info'} onClick={removeItem}>Ecosystem</NavLink>
                </li>
                <li className="nav-item mr-1">
                  <NavLink className="nav-link menues banner-text-gradient2" to={'/fest'} onClick={removeItem}>Startup Thiruvizha'24</NavLink>
                </li>
              </ul>

              {/* More Navbar Links */}
              <ul className="navbar-nav">
                <div className="nav-align">
                  {/* <button className="search-buttons" ><img src="/images/search-icon.png" alt="" className="" /></button> */}
                  {
                    token === null ? (
                      <>
                        <Link to={'/login'} className="nav-buttons" onClick={removeItem}>Login</Link>
                        <button className="nav-button" onClick={handleProfileType}>Get Started</button>
                      </>
                    ) : (
                      <Box sx={{ flexGrow: 0 }}>
                        {/* onClick={handleOpenUserMenu} */}
                        <Tooltip onClick={handleOpenUserMenu}>
                          <img
                            className="user-brandnewlogo"
                            // src={logo ? logo : "/images/user.png"}
                            src={(logo !== "undefined" && logo !== null) ? logo : "/images/user.png"}
                            alt={name}
                          />
                          {/* <IconButton sx={{ p: 0 }}>
                            <Avatar alt={startupName} src={logo} />
                          </IconButton> */}
                        </Tooltip>
                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {
                            loignType === 'ADMIN' ? (
                              <MenuItem onClick={adminDashboardPage} >
                                <Typography textAlign="center">Dashboard</Typography>
                              </MenuItem>
                            ) : (
                              <>
                                <MenuItem onClick={profilePage} >
                                  <Typography textAlign="center">Profile</Typography>
                                </MenuItem>
                                {
                                  continueWith === 'false' && (
                                    < MenuItem onClick={handlePasswordOpen}>
                                      <Typography textAlign="center">Change Password</Typography>
                                    </MenuItem>
                                  )
                                }
                              </>
                            )
                          }
                          <MenuItem onClick={logoutBtn} >
                            <Typography textAlign="center">Logout</Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    )
                  }
                </div>
              </ul>
            </div>
          </nav>
        </div >

      </div >
      <Menu
        sx={{ zIndex: '999' }}
        className="set_menu_nav"
        anchorEl={anchorElStartupMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorElStartupMenu)}
        onClose={handleCloseStartupMenu}
      >
        <StartupMenu handleCloseStartupMenu={handleCloseStartupMenu} />
      </Menu>
      <Menu
        sx={{ zIndex: '999' }}
        className="set_menu_nav"
        anchorEl={anchorElEnablersMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorElEnablersMenu)}
        onClose={handleCloseEnablersMenu}
      >
        <EnablerMenus handleCloseEnablersMenu={handleCloseEnablersMenu} />
      </Menu>
      {/* <div className="startupMenu">
        {
          openStartupMenu === true && (
            <StartupMenu setOpenStartupMenu={setOpenStartupMenu} />
          )
        }
      </div> */}
      {/* <div className="startupMenu">
        {
          openEnablerMenu === true && (
            <EnablerMenus setEnablerMenu={setEnablerMenu} />
          )
        }
      </div> */}
      <Dialog open={openPassword} maxWidth="md">
        <Changepassword handlePasswordClose={handlePasswordClose} />
      </Dialog>
    </>
  )
}

export default Navbar





// import React, { useContext } from 'react'
// import DataContext from '../../context/DataContext'
// import { Link } from 'react-router-dom'

// const Navbar = () => {
//   const { navigator } = useContext(DataContext)
//   const handleProfileType = () => {
//     navigator('/profile')
//   }
//   const handleAdmin = () => {
//     navigator('/admin')
//   }
//   return (
//     <div className="container-fluid">
//       <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top nav-pad" >
//         <Link className="navbar-brand nav-log-res" href="">
//           <img src="/images/blue horizontal.png" alt="" />
//         </Link>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>

//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//           <ul className="navbar-nav left-nav-content">
//             <li className="nav-item dropdown mr-2">
//               <Link
//                 className="nav-link dropdown-toggle"
//                 to=""
//                 role="button"
//                 data-toggle="dropdown"
//                 aria-expanded="false"
//               >
//                 For Startups
//               </Link>
//               <div className="dropdown-menu mr-2">
//                 <Link className="dropdown-item" to="">
//                   Action
//                 </Link>
//                 <Link className="dropdown-item" to="">
//                   Another action
//                 </Link>
//                 <div className="dropdown-divider"></div>
//                 <Link className="dropdown-item" to="">
//                   Something else here
//                 </Link>
//               </div>
//             </li>
//             <li className="nav-item dropdown">
//               <Link
//                 className="nav-link dropdown-toggle"
//                 to=""
//                 role="button"
//                 data-toggle="dropdown"
//                 aria-expanded="false"
//               >
//                 For Enablers
//               </Link>
//               <div className="dropdown-menu">
//                 <Link className="dropdown-item" to="">
//                   Action
//                 </Link>
//                 <Link className="dropdown-item" to="">
//                   Another action
//                 </Link>
//                 <div className="dropdown-divider"></div>
//                 <Link className="dropdown-item" to="">
//                   Something else here
//                 </Link>
//               </div>
//             </li>
//           </ul>
//           <Link href=""></Link>
//           <img
//             className="img-fluid nav-logo"
//             src="/images/blue horizontal.png"
//             alt=""
//           />
//           <ul className="navbar-nav ">
//             <li className="nav-item mr-2">
//               <Link className="nav-link" to="">
//                 Events
//               </Link>
//             </li>
//             <li className="nav-item mr-2">
//               <Link className="nav-link" to="">
//                 Resources
//               </Link>
//             </li>
//             <button className="nav-button" onClick={handleProfileType}>Get Started</button>
//             {/* <button className="nav-button" onClick={handleAdmin}>Login / Admin</button> */}
//           </ul>
//         </div>
//       </nav>
//     </div>
//   )
// }

// export default Navbar
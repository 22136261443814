import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Container, Dialog, DialogContent, Grid, Pagination, Stack, Tabs } from "@mui/material";
import { Typewriter } from "react-simple-typewriter";
import Navbar from "../navbar-page/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../footer-page/Footer";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { BiSearchAlt } from "react-icons/bi";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Eventregister from "../../Media-module/Media-layout-page/Event-Page/Eventregister";
const ExplorePage = () => {
  const { name, id } = useParams();
  let exploredetailsid
  if (id) {
    exploredetailsid = atob(id)
  }
  const { navigator, setPageLoading, fullScreen } = useContext(DataContext);
  const [tabName, setTabName] = useState("");
  const [buttonText, setButtonText] = useState("Explore");
  const [tabDetails, setTabDetails] = useState('');
  const [tabIndex, setTabIndex] = useState(1);
  const [tabSerive, setTabSerive] = useState([]);
  const [tabDetailsList, setTabDetailsList] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [searchInputValue, setSearchInputValue] = useState();
  const [opens, setOpens] = useState(false);
  const navigate = useNavigate();

  useDidMountEffect(() => {
    if (id) {
      getApi();
    }

  }, []);
  useDidMountEffect(() => {
    getExploreApi();
  }, [name]);
  const getExploreApi = () => {
    setTabSerive([])
    setPageLoading(true)
    apiService(`home/services?serviceName=`, "", "get").then((result) => {
      setPageLoading(false)
      if (result.data) {
        setTabDetailsList(result.data);
        const filteredTab = result.data.find((item) => item.name === name);
        if (filteredTab) {
          const { name, projects } = filteredTab;
          setTabDetails(name);
          setTabSerive(projects);
          setTabName(name);
          setPageTitle(name);
          var ind = result.data.indexOf(filteredTab)
          setTabIndex(ind + 1);
        }
      }
    }).catch((err) => {

    });
  };

  const [suggestProjectsList, setSuggestProjectsList] = useState([])
  const [otherProjectsList, setOtherProjectsList] = useState([])
  const getApi = () => {
    setSuggestProjectsList([])
    setOtherProjectsList([])
    setPageLoading(true)
    apiService(`home/explore/project/list?exploredetailsid=${exploredetailsid}`, "", "unauthget").then((result) => {
      setPageLoading(false)
      if (result.data) {
        var values = result.data
        if (values.suggestProjects) {
          setSuggestProjectsList(values.suggestProjects)
        }
        if (values.otherProjects) {
          setOtherProjectsList(values.otherProjects)
        }
      }
    }).catch((err) => {

    });
  };

  const handleChange = (event, newValue) => {
    const newTabDetails = tabDetailsList[newValue - 1];
    if (newTabDetails) {
      setTabName(newTabDetails.name);
      setButtonText("Explore");
      setPageTitle(newTabDetails.name);
      setTabDetails(newTabDetails);
      setTabSerive(newTabDetails.serviceList);
    }
  };
  const firstLetter = tabDetails.charAt(0);
  const remainingLetters = tabDetails.slice(1);

  const ShowgetApi = (value) => {
    setPageLoading(true)
    setTabSerive([])
    apiService(`home/services?serviceName=${value}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data[0]
          if (data && data.projects) {
            setTabSerive(data.projects)
          }
        }
      }).catch((err) => {

      });
  }

  const handleTabDetails = (value, index) => {
    setSuggestProjectsList([])
    setOtherProjectsList([])
    setTabSerive([])
    setTabIndex(index + 1)
    setTabDetails(value)
    setPageTitle(value);
    if (value === name && id) {
      getApi()
    } else {
      ShowgetApi(value)
    }

  };

  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  const handleRouteValue = (path) => {
    if (path !== "") {
      navigator(path);
    }
  };
  // event list api
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  useDidMountEffect(() => {
    listApi();
  }, [page]);
  const [eventList, setEventList] = useState([]);
  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    var req = {
      listSize: 3,
      pageNumber: page,
    };
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data && result.data.event) {
          setEventList(result.data.event);
          setTotalPage(result.data.totalPages);
        }
      })
      .catch((err) => { });
  };
  const handlepageChange = (event, value) => {
    setPage(value);
  };
  // event register modal
  const [eventId, setEventId] = useState(0);
  const handleRegister = (val) => {
    var token = localStorage.getItem("jwttoken");
    if (token === "") {
      navigator("/login");
    } else if (token === null) {
      navigator("/login");
    } else {
      setEventId(val);
      setOpens(true);
    }
  };
  const handleReportClose = () => {
    setOpens(false);
  };

  const handlenow = () => {
    navigate('/login')
  }
  return (
    <div className="back-color">
      <Navbar />
      <div className="back-layer"></div>
      <div className="back-layer1"></div>
      {/* <div className="top-layer"></div> */}
      <div className="set_z-ind">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Container>
              <div className="main-title">
                {/* <span className="small-tit">CONNECT,LEARN, SUCCEED </span> */}

                <div className="vectorimg-new">
                  <img src={"/images/Vector (1).png"} alt="vector" />
                </div>
                <p className="big-title2 mb-0 ">
                  <span className="big-title2 ml-2">
                    StartupTN
                    <div className="anim-div">
                      <div className="typewriter-container">
                        <div className="anim-abs">
                          <span>{buttonText}</span>
                        </div>
                        <div className="chipTextml-1">

                          <span>{firstLetter}</span>
                          <Typewriter
                            words={[remainingLetters]}
                            loop={false}
                            cursor
                            cursorStyle={<span className="cursor-style"></span>}
                            cursorBlinking={false}
                            typeSpeed={250}
                            deleteSpeed={60}
                            delaySpeed={1000}
                            style={{
                              background: "#B6CBFB",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </span>
                </p>
                <p className="titles-paras mt-3">
                  {/* Stay connected and informed with the latest happenings in the
                startup ecosystem. Our events and media resources are designed
                to inspire, educate, and empower entrepreneurs, innovators, and
                business leaders. */}
                  We Provide Our {tabDetails} Services Across The Entire Ecosystem. Here Is Our Directory Of Services Based On Our Established Policies. Search For Quick Services To Explore And Avail Of Them Hassle-Free.

                </p>
              </div>
            </Container>
          </Grid>
        </Grid>

        {/* <div className="search-bar">
        <div className="gray-back-search">
          <span>
            <BiSearchAlt style={{ fontSize: "30px", marginLeft: "2px" }} />
            <span className="eco-search"> Search Keyword</span>
          </span>
        </div>
        <input
          type="text"
          className="search-input"
          //  onChange={handleSearch}
        />

        <button className="arrow-btn" onClick={handleSearch}>
          <ArrowForwardIcon
          // onClick={() => handleRouteValue(list.path)}
          />
        </button>
      </div> */}
        <div class="search-bar">
          <BiSearchAlt className="upd-search-eco" /> <input type="text" class="search-input" onChange={handleSearch} placeholder="Search Keyword" autoFocus={true} />
        </div>

        <TabContext className="tab-div">
          <div className="name-tab">
            <Box className="tabs-boxs">
              <Tabs
                // value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {tabDetailsList.map((menu, index) => (
                  <Tab
                    label={`0${index + 1}. ${menu.name}`}
                    onClick={(e) => handleTabDetails(menu.name, index)}
                    className={tabIndex === index + 1 ? "Mui-selected" : ""}
                  />
                ))}
              </Tabs>
            </Box>
          </div>
        </TabContext>
        <div className="new-design-lists">
          <Container>
            <div className="event-title-line my-4">
              <h2 className="event-titles-new mt-3">{tabDetails}</h2>
              <div className="event-line-img bottom-down-arrow">
                <img src="/images/Vector (1).png" alt="LINE" />
              </div>
              {/* <p className="new-d-para my-5">
              Lorem ipsum dolor sit amet consectetur. Auctor ut tincidunt eu
              adipiscing hendrerit elementum arcu a neque. Quis posuere gravida
              et mauris mauris turpis non ac tristique.
            </p> */}
            </div>
          </Container>
          {
            (pageTitle === name && id) && (
              <>
                {
                  suggestProjectsList.length > 0 ? (
                    <div className="new-tab-crd">
                      <Container>
                        <h4 className="color-blue my-5">Suggested Projects </h4>
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          spacing={5}
                        >
                          {suggestProjectsList.map((list, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                              <div className="service_data">
                                <h5>{list.name}</h5>
                                <p>{list.brief}</p>
                                <div className="crd-actions-new">
                                  {/* <div className="gray-backg-new">
              <img src={list.logImg} alt="logos" />
              <span>{list.loginname}</span>
            </div> */}
                                  <button className="arrow-btn">
                                    <ArrowForwardIcon
                                      onClick={() => handleRouteValue(list.redirectPath)}
                                    />
                                  </button>
                                </div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </Container>
                    </div>
                  ) : (
                    <Container>
                      <div className="text-center w-100 my-5">
                        <h5 className="mb-4">Currently we couldn't find the related projects.</h5>
                        <div>
                          <img
                            src="/images/blue horizontal.png"
                            alt="blue horizontal.png"
                            className="logo_dash img-fluid"
                          />
                        </div>
                        <div className="mt-5">
                          <h3 className="title_p opa">StartupTN 3.0</h3>
                        </div>
                      </div>
                    </Container>

                  )
                }
              </>
            )
          }

          <div className="new-tab-crd">
            <Container>
              {
                suggestProjectsList.length > 0 && (
                  <h4 className="color-blue my-5">Other Related Projects </h4>
                )
              }
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={5}
              >
                {
                  (pageTitle === name && id) ? (
                    <>
                      {
                        otherProjectsList.map((list, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className="service_data">
                              <h5>{list.name}</h5>
                              <p>{list.brief}</p>
                              <div className="crd-actions-new">
                                {/* <div className="gray-backg-new">
                        <img src={list.logImg} alt="logos" />
                        <span>{list.loginname}</span>
                      </div> */}
                                <button className="arrow-btn">
                                  <ArrowForwardIcon
                                    onClick={() => handleRouteValue(list.redirectPath)}
                                  />
                                </button>
                              </div>
                            </div>
                          </Grid>
                        ))
                      }
                    </>
                  ) : (
                    <>
                      {
                        tabSerive.map((list, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className="service_data">
                              <h5>{list.name}</h5>
                              <p>{list.brief}</p>
                              <div className="crd-actions-new">
                                {/* <div className="gray-backg-new">
                            <img src={list.logImg} alt="logos" />
                            <span>{list.loginname}</span>
                          </div> */}
                                <button className="arrow-btn">
                                  <ArrowForwardIcon
                                    onClick={() => handleRouteValue(list.redirectPath)}
                                  />
                                </button>
                              </div>
                            </div>
                          </Grid>
                        ))
                      }
                    </>
                  )
                }
              </Grid>
            </Container>
          </div>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>
          <div className="backg-color-new mt-5">
            <Container>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <img
                    src="/images/smcrd-person.png"
                    alt="person"
                    className="newd-person"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="banner-content-new">
                    <h4>Need support for a Literacy-related query? </h4>
                    <p>Don't worry, we're here to help you!</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div>
                    <button className="avail-btn-newd" onClick={handlenow}>
                      Raise Support ticket
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="card-section-new-design my-5">

            <Container>
              <div className="new-crd-title-flex mb-3">
                <h2>{tabName ? `Events Related to ${tabName} ` : ' '}</h2>
                <div className="event-pagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handlepageChange}
                    />
                  </Stack>
                </div>
              </div>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                {eventList.length > 0 ? (
                  <Grid container spacing={2}>
                    {eventList.map((item, index) => (
                      <Grid item sm={12} md={4} lg={4}>
                        <div className="event-list">
                          <div
                            class="card"
                            style={{
                              width: "18rem",
                              minHeight: "380px",
                              borderRadius: "10px",
                            }}
                          >
                            <div className="event-img">
                              <img
                                src={item.imageName}
                                class="event-home-img-top"
                                alt=""
                              />
                              <div className="img-top">
                                <span>{item.category}</span>
                              </div>
                              <div className="event-time-top">
                                <div className="home-event-days">
                                  {item.startDay && (
                                    <>
                                      <div className="time-bg">
                                        <h6 className="time-color">
                                          {item.startDay}
                                        </h6>
                                        <span>{item.startMonth}</span>
                                      </div>
                                    </>
                                  )}
                                  {item.endDay && (
                                    <>
                                      <div className="time-bg ml-2">
                                        <h6 className="time-color">
                                          {item.endDay}
                                        </h6>
                                        <span>{item.endMonth}</span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="card-body" style={{ padding: "10px" }}>
                              <div className="event-details">
                                <h5>{item.title}</h5>
                                <span className="event-location">
                                  Lorem ipsum dolor sit amet consectetur. Auctor
                                  ut tincidunt eu adipiscing hendrerit elementum
                                </span>
                                {/* <p>{item.description}</p> */}
                                <h6 className="event-time mt-2">
                                  <img
                                    src="/images/clock-icon.png"
                                    alt=""
                                    className="mr-2"
                                  />
                                  {item.formattedTime}
                                  {/* - <span className="event-free">({item.meetSpace})</span> */}
                                </h6>
                                {item.location ? (
                                  <span className="event-location">
                                    <img
                                      src="/images/location-icon.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    {item.location}
                                  </span>
                                ) : (
                                  // <span className="event-location">&nbsp;</span>
                                  <span className="event-location">
                                    <img
                                      src="/images/online-symbal.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    Online
                                  </span>
                                )}

                                <div className="reg-fees mt-2">
                                  {item.paid === false ? (
                                    <span className="event-free">Free</span>
                                  ) : (
                                    <span className="event-free">
                                      ₹{item.amount}
                                    </span>
                                  )}
                                  {
                                    item.showRegisterButton === true && (
                                      <div>
                                        <button
                                          className="reg-btn"
                                          onClick={() => handleRegister(item.id)}
                                        >
                                          Register
                                        </button>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid item sm={12}>
                  <div className="text-center">
                    <h3>Events coming soon</h3>
                  </div>
                  </Grid>
                )}
              </Grid>
            </Container>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
      <Dialog fullScreen={fullScreen} maxWidth={"lg"} open={opens}>
        <DialogContent className="dialog-modal">
          <div>
            <span
              style={{ color: "#2260FF", cursor: "pointer" }}
              onClick={handleReportClose}
            >
              <ArrowBackIcon /> Back
            </span>
          </div>
          <Eventregister
            categoryId={eventId}
            setOpens={setOpens}
            via={"home"}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ExplorePage;

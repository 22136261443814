import { Box, Container, Grid, IconButton, Modal } from '@mui/material'
import React, { useState } from 'react'
import Navbar from '../navbar-page/Navbar'
import Footer from '../footer-page/Footer'
import { Link } from 'react-router-dom'
import CloseIcon from "@mui/icons-material/Close";

const PdfModal = ({ open, handleClose, pdfUrl }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height: '90%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 6,
                    overflow: 'auto',
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 16,
                        top: 16,
                        backgroundColor: "red",
                        borderRadius: "50%",
                        color: "white",
                        padding: "4px",
                        '&:hover': {
                            backgroundColor: "red",
                            color: "white",
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <iframe
                    src={pdfUrl}
                    title="PDF Document"
                    style={{ width: '100%', height: '100%' }}
                />
            </Box>
        </Modal>
    );
};

const ContactUs = () => {
    const [open, setOpen] = useState(false);
    const pdfUrl = "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Tansim-Order.pdf";
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <div className="back-color1">
                <Navbar />
                <div className="back-layer"></div>
                <div className="back-layer1"></div>
                {/* <div className="top-layer"></div> */}
                <div className='Grievances-bg1'>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="main-title-head-Grievance">
                                <h3 className='res-h3 mb-4 text-center'>Grievance Mechanism</h3>
                            </div>
                            <div className="Grievance-box">
                                <div className='text-center'>
                                    <img src="/images/Grievance-star.png" alt="" />
                                    <h4 className='Grievance-title'>Nodal Officers For Grievances</h4>
                                    <h5 className='Grievance-name'>Thiru. Sivarajah Ramanathan</h5>
                                    <h6 className='Grievance-Chief'>Chief Executive Officer, StartupTN</h6>
                                    <h6 className='Grievance-link'>ceo@startuptn.in</h6>
                                </div>
                            </div>

                            <div className='Submit-Grievances '>
                                <h5 className='mt-5'>Submit Your Grievances.</h5>
                                <h4>Connect with us at ceo@startuptn.in</h4>
                                <p>Here you may request/submit grievances regarding incubation support, funding support, public procurement, regular issues or any other query. The grievances raised will be forwarded to the officers concerned and will be resolved in 5 working days.</p>
                            </div>
                            <div className='text-center'>
                                <button className="ViewOrder-btn" onClick={handleOpen}>
                                    View Order
                                </button>
                                <PdfModal open={open} handleClose={handleClose} pdfUrl={pdfUrl} />
                            </div>
                            <div className='text-center'>
                                <button className="Raise-btn-Grievances mt-4">
                                    Raise Support Ticket
                                </button>
                                <button className="Track-btn-Grievances mt-4">
                                    Track an Issue
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="Grievances-bg">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Container>
                                <div className="main-title-head1">
                                    <h3 className='res-h3 mb-4 text-center'>Contact Us</h3>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        spacing={2}
                                        className='my-5'
                                    >
                                        <Grid item xs={12} sm={12} md={4} lg={4} >
                                            <div className='white-box text-center'>
                                                <center><img src='/images/CS-Ph.png' className='img-fluid mb-3' alt='' /></center>
                                                <h6 className='belu-clr mt-2'><b>Contact - 155343</b></h6>
                                                <h6 className='belu-clr'><b>(+91) 44 22256789</b></h6>
                                                <p className='gru-clr'>Dedicated helpline for startups</p>
                                                <p className='timings-cont'>Monday - Friday<br />10.00 AM - 06.00 PM</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <div className='white-box text-center'>
                                                <center><img src='/images/CS-loc.png' className='img-fluid mb-3' alt='' /></center>
                                                <h6 className='belu-clr mt-2'><b>Office Address</b></h6>
                                                <p className='gru-clr'>StartupTN Office Space, 10th Floor, Chennai Metro Rail Limited Building, near Nandanam Metro Station, Chennai, Tamil Nadu 600035</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <div className='white-box text-center'>
                                                <center><img src='/images/CS-supp.png' className='img-fluid mb-3' alt='' /></center>
                                                <p className='gru-clr'>For Support:</p>
                                                <h6 className='belu-clr mt-2'><b><img src='/images/Cs-Mail.png' alt='' className='img-fluid mr-2' />support@startuptn.in</b></h6>
                                                <h6 className='belu-clr mt-2'><b><img src='/images/phn.png' alt='' className='mr-2' />+91 86103 31001</b></h6>
                                                <p className='gru-clr'>Quick Links</p>
                                                <div className='text-center'>
                                                    <div className='social_div'>
                                                        <ul className='social_medias'>
                                                            <li>
                                                                <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                                                                    <img src='/images/icon/fb.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>
                                                                    <img src='/images/icon/youtube.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>
                                                                    <img src='/images/icon/insta.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://x.com/TheStartupTN'} target='_blank'>
                                                                    <img src='/images/icon/twitters.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'>
                                                                    <img src='/images/icon/linked.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link >
                                                                    <img src='/images/icon/whatsapp.png' alt='' className='contact-icon' />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <img src='/images/cs-map.png' className='img-fluid mb-5' alt='' />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default ContactUs
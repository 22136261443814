import React, { useState } from 'react'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import apiService from '../../../api/apiService'
import { Container, Grid } from '@mui/material'
import cryptodecryptJS from '../../../hooks/cryptodecryptJS'

const MapSection = () => {

    useDidMountEffect(() => {
        mapGetApi()
    }, [])
    const [districtMapDetails, setDistrictMapDetails] = useState([])
    const [selectedHub, setselectedHub] = useState({})
    const mapGetApi = () => {
        var req = {

        }
        apiService('hub/list', req, 'unauthpost').then((result) => {
            if (result && result.data && result.data.hubs) {
                setDistrictMapDetails(result.data.hubs)
                const filtered = result.data.hubs.filter((item) =>
                    item.name.toLowerCase().includes('chennai')
                );
                setselectedHub(filtered[0])
                // result.data.hubs.forEach(element => {

                //     if (element.name === 'chennai') {
                //         setselectedHub(element)
                //     }
                // });
            }
        }).catch((err) => {

        });
    }
    const [selectedDistrict, setSelectedDistrict] = useState('chennai')
    const [districtName, setDistrictName] = useState('chennai')
    const handleDistrict = (value) => {
        setSelectedDistrict(value)
        setDistrictName(value)
        const filtered = districtMapDetails.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        setselectedHub(filtered[0])
        // debugger
        // districtMapDetails.forEach(element => {

        //     if (element.name === value) {
        //         setselectedHub(element)
        //     }
        // });
    }
    const handleMouseEnter = (value) => {
        setSelectedDistrict(value)
    }
    const handleMouseLeave = () => {
        setSelectedDistrict(districtName)
    }
    const [filterMapValue, setFilterMapValue] = useState('')
    const handleFilterMap = (value) => {
        setFilterMapValue(value)
    }
    return (
        <section className="presence-section">
            <div className="presence">
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Container>
                            <div className="map-div">
                                <div className='map-div-bg'>
                                    <img src='/images/map/map-bg.png' alt='' className='img-fluid map-bg' />
                                    <img src='/images/map/Thiruvallur.png' alt='' className='img-fluid Thiruvallur' />
                                    <img src='/images/map/Chennai.png' alt='' className='img-fluid Chennai c-pointer'
                                        onMouseEnter={() => handleMouseEnter('chennai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Chengalpattu.png' alt='' className='img-fluid Chengalpattu' />
                                    <img src='/images/map/Kancheepuram.png' alt='' className='img-fluid Kancheepuram' />
                                    <img src='/images/map/Viluppuram.png' alt='' className='img-fluid Viluppuram' />
                                    <img src='/images/map/Mayiladuthurai.png' alt='' className='img-fluid Mayiladuthurai' />
                                    <img src='/images/map/Ariyalur.png' alt='' className='img-fluid Ariyalur' />
                                    <img src='/images/map/Perambalur.png' alt='' className='img-fluid Perambalur' />
                                    <img src='/images/map/Ranipet.png' alt='' className='img-fluid Ranipet' />
                                    <img src='/images/map/Vellore.png' alt='' className='img-fluid Vellore' />
                                    <img src='/images/map/Tiruvannamalai.png' alt='' className='img-fluid Tiruvannamalai' />
                                    <img src='/images/map/Tirupathur.png' alt='' className='img-fluid Tirupathur' />
                                    <img src='/images/map/Krishnagiri.png' alt='' className='img-fluid Krishnagiri c-pointer'
                                        onMouseEnter={() => handleMouseEnter('hosur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Dharmapuri.png' alt='' className='img-fluid Dharmapuri' />
                                    <img src='/images/map/Kallakurichi.png' alt='' className='img-fluid Kallakurichi' />
                                    <img src='/images/map/Cuddalore.png' alt='' className='img-fluid Cuddalore c-pointer'
                                        onMouseEnter={() => handleMouseEnter('cuddalore')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Salem.png' alt='' className='img-fluid Salem c-pointer'
                                        onMouseEnter={() => handleMouseEnter('salem')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Erode.png' alt='' className='img-fluid Erode c-pointer'
                                        onMouseEnter={() => handleMouseEnter('erode')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Nilgiris.png' alt='' className='img-fluid Nilgiris' />
                                    <img src='/images/map/Thanjavur.png' alt='' className='img-fluid Thanjavur c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Thanjavur Regional Hub')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Tiruvarur.png' alt='' className='img-fluid Tiruvarur' />
                                    <img src='/images/map/Nagapattinam.png' alt='' className='img-fluid Nagapattinam' />
                                    <img src='/images/map/Tiruchirappalli.png' alt='' className='img-fluid Tiruchirappalli c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Tiruchirappalli Satellite Hub')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Namakkal.png' alt='' className='img-fluid Namakkal' />
                                    <img src='/images/map/Karur.png' alt='' className='img-fluid Karur' />
                                    <img src='/images/map/Tiruppur.png' alt='' className='img-fluid Tiruppur' />
                                    <img src='/images/map/Coimbatore.png' alt='' className='img-fluid Coimbatore c-pointer'
                                        onMouseEnter={() => handleMouseEnter('coimbatore')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Dindigul.png' alt='' className='img-fluid Dindigul' />
                                    <img src='/images/map/Pudukkottai.png' alt='' className='img-fluid Pudukkottai' />
                                    <img src='/images/map/Madurai.png' alt='' className='img-fluid Madurai c-pointer'
                                        onMouseEnter={() => handleMouseEnter('madurai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Theni.png' alt='' className='img-fluid Theni' />
                                    <img src='/images/map/Sivagangai.png' alt='' className='img-fluid Sivagangai' />
                                    <img src='/images/map/Ramanathapuram.png' alt='' className='img-fluid Ramanathapuram' />
                                    <img src='/images/map/Virudhunagar.png' alt='' className='img-fluid Virudhunagar' />
                                    <img src='/images/map/Tenkasi.png' alt='' className='img-fluid Tenkasi' />
                                    <img src='/images/map/Thoothukudi.png' alt='' className='img-fluid Thoothukudi' />
                                    <img src='/images/map/Tirunelveli.png' alt='' className='img-fluid Tirunelveli c-pointer'
                                        onMouseEnter={() => handleMouseEnter('thirunelveli')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Kanyakumari.png' alt='' className='img-fluid Kanyakumari' />

                                    <img src='/images/map/Chennai_hub.png' alt='' className='img-fluid Chennai_hub c-pointer' style={{ display: selectedDistrict === 'chennai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('chennai')}
                                        onMouseEnter={() => handleMouseEnter('chennai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Hosur_hub.png' alt='' className='img-fluid Hosur_hub c-pointer' style={{ display: selectedDistrict === 'hosur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('hosur')}
                                        onMouseEnter={() => handleMouseEnter('hosur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Cuddalore_hub.png' alt='' className='img-fluid Cuddalore_hub c-pointer' style={{ display: selectedDistrict === 'cuddalore' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('cuddalore')}
                                        onMouseEnter={() => handleMouseEnter('cuddalore')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Salem_hub.png' alt='' className='img-fluid Salem_hub c-pointer' style={{ display: selectedDistrict === 'salem' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('salem')}
                                        onMouseEnter={() => handleMouseEnter('salem')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Erode_hub.png' alt='' className='img-fluid Erode_hub c-pointer' style={{ display: selectedDistrict === 'erode' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('erode')}
                                        onMouseEnter={() => handleMouseEnter('erode')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Coimbatore_hub.png' alt='' className='img-fluid Coimbatore_hub c-pointer' style={{ display: selectedDistrict === 'coimbatore' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('coimbatore')}
                                        onMouseEnter={() => handleMouseEnter('coimbatore')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Tiruchirappalli_hub.png' alt='' className='img-fluid Tiruchirappalli_hub c-pointer' style={{ display: selectedDistrict === 'Tiruchirappalli Satellite Hub' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tiruchirappalli Satellite Hub')}
                                        onMouseEnter={() => handleMouseEnter('Tiruchirappalli Satellite Hub')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Thanjavur_hub.png' alt='' className='img-fluid Thanjavur_hub c-pointer' style={{ display: selectedDistrict === 'Thanjavur Regional Hub' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Thanjavur Regional Hub')}
                                        onMouseEnter={() => handleMouseEnter('Thanjavur Regional Hub')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Madurai_hub.png' alt='' className='img-fluid Madurai_hub c-pointer' style={{ display: selectedDistrict === 'madurai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('madurai')}
                                        onMouseEnter={() => handleMouseEnter('madurai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Tirunelveli_hub.png' alt='' className='img-fluid Tirunelveli_hub c-pointer' style={{ display: selectedDistrict === 'thirunelveli' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('thirunelveli')}
                                        onMouseEnter={() => handleMouseEnter('thirunelveli')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </div>
                            </div>
                            <div className="map-filiter-home">
                                <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('Hub')}>
                                    <div className={filterMapValue === 'Hub' ? 'seletedHub defauildRound' : 'seletedHub defauildRound'}></div>
                                    <span>Selected Hub</span>
                                </div>
                                <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('DistrictsMap')}>
                                    <div className={filterMapValue === 'DistrictsMap' ? 'seletedDistrictsMapped defauildRound' : 'seletedDistrictsMapped defauildRound'}></div>
                                    <span>Districts mapped for Selected Hub</span>
                                </div>
                                <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('Hubs')}>
                                    <div className={filterMapValue === 'Hubs' ? 'seletedHubs defauildRound' : 'seletedHubs defauildRound'}></div>
                                    <span>Hubs</span>
                                </div>
                                <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('seletedDistricts')}>
                                    <div className={filterMapValue === 'seletedDistricts' ? 'seletedDistricts defauildRound' : 'seletedDistricts defauildRound'}></div>
                                    <span>Districts</span>
                                </div>
                            </div>
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="map-content">
                            <h2 className="heading-line">
                                <span className="heading-line_span"></span> <span className="chennai-text">{selectedHub.name}</span>
                            </h2>

                            <p className="map-detail-parag">{selectedHub.brief}</p>
                            <p>
                                Districts - <span className='district_span'>{selectedHub.districts}</span>
                            </p>
                            <div className="map-boxdetails">
                                <div className="map-det-flex">
                                    <div className="map-boxdetails-div">
                                        <h4>{selectedHub.startups}</h4>
                                        <p>Startups</p>
                                    </div>
                                    <div className="map-boxdetails-div">
                                        <h4>{selectedHub.universities}
                                        </h4>
                                        <p>Educational Institutions</p>
                                    </div>
                                    <div className="map-boxdetails-div">
                                        <h4>{selectedHub.incubationCenter}</h4>
                                        <p>Incubation Centers</p>
                                    </div>
                                </div>
                                <div className="map-det-flex">
                                    <div>
                                        <div className="map-boxdetailseds-div mb-3">
                                            <p>{selectedHub.address}</p>
                                        </div>
                                        <div className="hub-details">
                                            <div className="hub_img-div">
                                                <img src={selectedHub.inchargeLogoName ? selectedHub.inchargeLogoName : '/images/user.png'} alt="hub-img" className="hub_img" />
                                            </div>
                                            <div className="hub_name_details">
                                                <p className="hubname mb-0">{selectedHub.name} Incharge</p>
                                                <p className="hubhead mb-0">{selectedHub.inchargeName}</p>
                                                {
                                                    selectedHub.inchargEmail && (
                                                        <p className="hubmail mb-0"><img src="/images/hub-mail.png" alt="hubmailid" /> {cryptodecryptJS(selectedHub.inchargEmail)}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='map-boxdetailseds-div-map '>

                                        <iframe
                                            src={selectedHub.mapLink}
                                            width="100"
                                            height="200"
                                            style={{ border: 0 }}
                                            allowFullScreen
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Grid>
                </Grid>


            </div>
        </section>
    )
}

export default MapSection
import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import $ from 'jquery';
const Footer = () => {
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $("html, body").animate({
        scrollTop: 0
      }, 600);
      return false;
    });
  });

  const scrollToTop = () => {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      600
    );
  };
  return (
    <>
      <footer className="footer">

        <div className='footer_details '>
          <div className="container">
            <section className="">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <Link to={'https://startuptn.in/'} target='_blank'>
                    <img src="/images/startup-white.png" className="p-0 footer-img" alt="" />
                    {/* <img src="/images/TamilNadu_Logo.svg" alt="" className="img-fluid nav-logo-profile1"/> */}
                  </Link>

                  {/* <img src="/images/TNLogoWhiteLogo1.png" alt="blue horizontal.png" className='logo_tanfund_img' /> */}
                </div>
                <div className="col-lg-6 col-md-6 footer-text-gray">
                  <h3 className="my-3 font-weight-bolder c-gray">Contact</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='set_flex'>
                      <div>
                        <img src='/images/location.png' className='foo_icon_img' alt="" />
                      </div>
                      <div>
                      <p>StartupTN Office Space, 10th Floor, <br /> Chennai Metro Rail Limited Building,<br /> near Nandanam Metro Station, <br /> Chennai, Tamil Nadu-600035</p>
                      </div>
                    </li>
                    <li className='set_flex'>
                      <div>
                        <img src='/images/mail-footer.png' className='foo_icon_img' alt="" />
                      </div>
                      <p>support@startuptn.in</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-3 footer-text-gray">
                  <h3 className="my-3 font-weight-bolder c-gray">Quick Links</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        For Startups
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        For Enablers
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/ecosystem-info' onClick={scrollToTop} >
                        Ecosystem
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/events/list' onClick={scrollToTop} >
                        Events
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/resource-page' onClick={scrollToTop} >
                        Resources
                      </Link>
                    </li>
                    <li className='pb-3'>
                    <Link className="footer-text-gray" to='/tender' onClick={scrollToTop} >
                      Tenders
                      </Link>
                    </li>
                    <li className='pb-3'>
                    <Link className="footer-text-gray" to='/careers' onClick={scrollToTop} >
                      Careers
                      </Link>
                    </li>
                  </ul>

                </div>
                <div className="col-lg-3 col-md-3 footer-text-gray">
                  <h3 className="my-3 font-weight-bolder c-gray">Legal Links</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/privacy-policy' onClick={scrollToTop} >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/terms-condition' onClick={scrollToTop} >
                        Terms & condition
                      </Link>
                    </li>
                    <li className='pb-3'>
                      <Link className="footer-text-gray" to='/contactus' onClick={scrollToTop} >
                        Contact & Support
                      </Link>
                    </li>
                   
                    {/* <li className='pb-3'>
                      <Link className="footer-text-gray" to='' onClick={scrollToTop} >
                        FAQ's Page
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6">
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className='my-3'>
                    <div className="social_icon">
                      <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                        <img src='/images/facebook.svg' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://x.com/TheStartupTN'} target='_blank'>
                        <img src='/images/logo/icons8-twitter.svg' className='img-fluid' alt="" style={{width:'35px',borderRadius:'50%',background:'#fff'}}/>
                      </Link>
                      <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'>
                        <img src='/images/linkedin.svg' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>
                        <img src='/images/instagram.svg' className='img-fluid' alt="" />
                      </Link>
                      <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>
                        <img src='/images/youtube.svg' className='img-fluid' alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

            </section>
            {/* <p className='hr' /> */}
            {/* <div className="text-center">
            

          </div> */}
          </div>
        </div>
        <span id="backtotop" title="Back to top" style={{ display: 'none' }}>&uarr;</span>
      </footer>
    </>
  )
}

export default Footer
import React from 'react'

const Tnfesteventagenda = () => {
  return (
    <div>
      <div className='cm-bg-img-tiru'>
      <div className="agenda-head">
      <h2>Agenda</h2>
      <h6>Coming Soon..</h6>
      </div>
      </div>
        <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='img-fluid' />
    </div>
  )
}

export default Tnfesteventagenda
import React, { useContext, useState } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../home-module/navbar-page/Navbar";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { Autocomplete, Button, Dialog, DialogContent, FormControl, FormHelperText, Grid, List, TextField, } from "@mui/material";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Controller, useForm } from "react-hook-form";
import notifyService from "../../../api/notifySerivce";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import cryptoJS from "../../../hooks/cryptoJS";
import MaskDialog from "../Mask-Dialogbox/MaskDialog";
import Qrcode from "../startup-profile/Qr-Barcode/Qrcode";

const InvestorProfileLayout = ({ path }) => {
  const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, validatePhoneNumber, fullScreen, modalstyle,
    linkedInPattern, validateEmail, getApi, formData, handlemaskClickOpen, handleOpenEdit, editBtn, setEditBtn, handleEditBtn, editKey, setEditKey, userProfileId, showViewbtn } = useContext(DataContext);
  const { handleSubmit, control, setValue, getValues, reset, clearErrors, formState: { errors }, } = useForm({ values: formData });


  useDidMountEffect(() => {
    sectorsdropdownApi();
    if (formData.focusedSector) {
      setSelectedValues(formData.focusedSector)
    }
  }, []);
  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data;

              setSectors(values.sectors);
            }
          }
        }
      })
      .catch((err) => { });
  };
  useDidMountEffect(() => {
    getApi();
  }, [userProfileId, showViewbtn]);
  const [selectedValues, setSelectedValues] = useState([]);
  const handleSelectSector = (value) => {
    setValue("focusedSector", value);
    setSelectedValues(value);
    clearErrors();
  };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileUrl) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpg" ||
            img_crt_type === "png" ||
            img_crt_type === "jpeg"
          ) {
            setValue(filekey, "");
            setValue(fileName, "");
            setValue(fileUrl, '')
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1)
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const onSubmit = (data) => {
    let ids = [];
    data.phone = cryptoJS(data.phone);
    data.email = cryptoJS(data.email);
    data?.focusedSector?.forEach((name) => {
      sectors?.forEach((val) => {
        if (name === val?.name) {
          ids?.push({ name, id: val.id.toString() });
        }
      });
    });
    const getIds = ids.map((val) => val.id);
    console.log(getIds);

    const payload = {
      ...data,
      focusedSectorIds: getIds,
    };
    debugger
    apiService("userprofile/save", payload, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          if (editKey === "") {
            setEditBtn(false);
          } else {
            setEditKey("");
          }
          getApi();
        }

      })
      .catch((err) => { });
  };
  const [qrModalopen, setQrModalOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setQrModalOpen(true);
  };

  const handleModalClose = () => {
    setQrModalOpen(false);
  };
  const downloadBarcode = () => {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', formData.barcode);
    link.setAttribute('download', formData.barcode); // Set desired file name here
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  const downloadQrcode = () => {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', formData.qr);
    link.setAttribute('download', formData.qr); // Set desired file name here
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  const [smartOpen, setSmartOpen] = useState(false)
  const availsmartopen = () => {
    setPageLoading(true)
    setSmartOpen(true)
    apiService('userprofile/smartcard/avail', '', 'get')
      .then((result) => {
        setPageLoading(false)
        setTimeout(() => {
          availsmartClose()
        }, 1500);
        getApi()
      }).catch((err) => {
        console.log(err);
      });
  }
  const availsmartClose = () => {
    setSmartOpen(false)
  }
  return (
    <div className="bg-color">
      <div>
        <Navbar />
        <div className="container-fluid flu-class">
          <div className="inv-prof-img"></div>
          <div className="user-profile">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="user-boxs">
                <div className="first-box">
                  <div className="all-userdetails1">
                    <div className="d-flex align-items-center">
                      <div>
                        {editKey === "imageName" ? (
                          <div className="form-group mt-2">
                            <div className="logo_div">
                              <img
                                src={getValues('imageUrl')}
                                className="user_logo"
                                alt={getValues('imageName')}
                              />
                              <div className="camera_icon">
                                <Controller
                                  name="imageName"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: `logo is required` }}
                                  render={({ field }) => (
                                    <Button
                                      {...field}
                                      onChange={(e) =>
                                        handleFileUpload(e, "image", "imageName", "imageUrl")
                                      }
                                      component="label"
                                      startIcon={<CameraAltIcon />}
                                    >
                                      <VisuallyHiddenInput type="file" />
                                    </Button>
                                  )}
                                />
                              </div>
                              <FormHelperText className="text-danger">
                                {errors.imageName && errors.imageName.errorMessage}
                              </FormHelperText>
                            </div>

                            <div className="img-btn-div">
                              <Button type="submit" sx={{ marginLeft: "10px" }}>
                                <DoneOutlineIcon
                                  sx={{ fontSize: "16px", cursor: "pointer" }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span>
                            {" "}
                            <img
                              className="user-brandlogo img-fluid"
                              src={
                                formData.imageName
                                  ? formData.imageName
                                  : "/images/user.png"
                              }
                              alt="logo"
                            />
                            {editBtn === true && (
                              <EditIcon
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() => handleEditBtn("imageName")}
                              />
                            )}
                          </span>
                        )}
                      </div>
                      <div className="brand-details ml-3">
                        {editKey === "name" ? (
                          <div className="d-inline-block">
                            <div className="d-flex align-items-start">
                              <div>
                                <Controller
                                  name="name"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: `Phone is required`,
                                    maxLength: {
                                      value: 10,
                                      validate: validatePhoneNumber,
                                    },
                                    minLength: {
                                      value: 10,
                                      validate: validatePhoneNumber,
                                    },
                                  }}
                                  render={({ field }) => (
                                    <input
                                      placeholder="Enter name"
                                      type="text"
                                      {...field}
                                      className="sml-inp"
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.name && errors.name.message}
                                </FormHelperText>
                              </div>
                              <Button type="submit">
                                <DoneOutlineIcon
                                  sx={{ fontSize: "16px", cursor: "pointer" }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span className="inv-prof-name">
                            <h4 className="font-weight-bold">
                              {formData.name}

                              <span>
                                <img
                                  src={"/images/verified-tick.png"}
                                  alt="verified"
                                />
                              </span>
                            </h4>
                            {editBtn === true && (
                              <EditIcon
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() => handleEditBtn("name")}
                              />
                            )}
                          </span>
                        )}
                        {editKey === "focusedSector" ? (
                          <div className="d-inline-block">
                            <div className="d-flex align-items-start">
                              <div>
                                <FormControl sx={{ width: 300 }}>
                                  <Controller
                                    name="focusedSector"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Autocomplete
                                        {...field}
                                        multiple={true}
                                        options={sectors.map(
                                          (list) => list.name
                                        )}
                                        value={selectedValues}
                                        onChange={(e, selectedOption) =>
                                          handleSelectSector(selectedOption)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            // label="Focus Sector *"
                                            placeholder="Seletct Focus Sector"
                                            error={!!error}
                                            helperText={
                                              error
                                                ? "Focus Sector is required"
                                                : ""
                                            }
                                          />
                                        )}
                                        fullWidth
                                        margin="normal"
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.focusedSector &&
                                      errors.focusedSector.message}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                              <Button type="submit">
                                <DoneOutlineIcon
                                  sx={{ fontSize: "16px", cursor: "pointer" }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span className="foccused-sector-edit">
                            <p className="para mb-0 mr-3">
                              <span>
                                {formData.focusedSector &&
                                  formData.focusedSector[0]}

                                {/* <span style={{ color: "gray" }}>& More</span> */}
                              </span>
                            </p>
                            {editBtn === true && (
                              <EditIcon
                                sx={{ fontSize: "14px", cursor: "pointer" }}
                                onClick={() => handleEditBtn("focusedSector")}
                              />
                            )}
                          </span>
                        )}

                        <div>
                          <span style={{ fontSize: "12px", color: "gray" }}>
                            {formData.state}, {formData.country}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          className="user-cardlogo"
                          src={"/images/debitCrd.png"}
                          alt="bankCards"
                        />
                      </div>

                      <div>
                        <p className="par m-0">UID No.</p>

                        <span
                          style={{
                            color: "#020671",
                            fontSize: "16px",
                            fontWeight: "600",
                            cursor: 'pointer'
                          }}
                          onClick={handleClickOpen}
                        >
                          {formData.uid}
                        </span>
                        <div>

                          {(formData.smartcardstatus === 0) && (
                            <p className="avail-smart-style" onClick={() => availsmartopen()}>Avail SmartCard</p>)}
                          {(formData.smartcardstatus === 1) && (<p className="avail-smart-style-p">Requested</p>)}
                          {(formData.smartcardstatus === 2) && (<p className="avail-smart-style-p">Under Process</p>)}
                          {(formData.smartcardstatus === 3) && (<p className="avail-smart-style-p">Availed</p>)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="mr-2">
                          <span style={{ fontSize: "10px" }}>
                            Engagement Level :
                            <span
                              style={{
                                color: "#020671",
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                            >
                              ACE
                            </span>
                          </span>
                        </div>
                        <div>
                          <span style={{ fontSize: "10px" }}>
                            Member Since :
                            <span
                              style={{
                                color: "#020671",
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                            >
                              {formData.memberSince}
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className="det-flex mt-3">
                        <div className="mobile-resp">
                          <span className="ml-2" style={{ fontSize: "14px" }}>
                            {editKey === "linkedIn" ? (
                              <div className="d-inline-block">
                                <div className="d-flex align-items-start">
                                  <div>
                                    <FormControl sx={{ width: 150 }}>
                                      <Controller
                                        name="linkedIn"
                                        control={control}
                                        defaultValue=""
                                        rules={{ value: linkedInPattern, message: "Invalid LinkedIn profile URL" }}
                                        render={({ field }) => (
                                          <TextField
                                            variant="outlined"
                                            label="LinkedIn"
                                            placeholder="Enter LinkedIn"
                                            fullWidth
                                            type="text"
                                            {...field}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.linkedIn && errors.linkedIn.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <Button type="submit">
                                    <DoneOutlineIcon
                                      sx={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <span>
                                <a
                                  href={formData.linkedIn}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src="/images/linkedin-logo.png" alt="linkedin logo" />
                                </a>
                                {/* {formData.linkedIn}{" "} */}
                                {editBtn === true && (
                                  <EditIcon
                                    sx={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleEditBtn("linkedIn")
                                    }
                                  />
                                )}
                              </span>
                            )}
                          </span>
                        </div>
                        {editKey === "phone" ? (
                          <div className="d-inline-block">
                            <div className="d-flex align-items-start">
                              <div>
                                <FormControl sx={{ width: 150 }}>
                                  <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: `Phone is required`,
                                      maxLength: {
                                        value: 10,
                                        validate: validatePhoneNumber,
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Phone Number *"
                                        placeholder="Enter Phone Number"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        onKeyDown={(e) => validateNumberonly(e)}
                                        inputProps={{ maxLength: 10 }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.phone && errors.phone.message}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                              <Button type="submit">
                                <DoneOutlineIcon
                                  sx={{ fontSize: "16px", cursor: "pointer" }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span>
                            <FaPhoneAlt
                              className="mx-1"
                              style={{
                                color: "#020671",
                              }}
                            />
                            {/* {formData.phone} */}
                            {
                              showViewbtn !== 0 ? (
                                <span className="blur_css">+91 XXXXX-XXXXX</span>
                              ) : (
                                <span>+91 {formData.phone}</span>
                              )
                            }
                            {editBtn === true && (
                              <EditIcon
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() => handleEditBtn("phone")}
                              />
                            )}
                          </span>
                        )}
                        <div className="mobile-resp">
                          <div>
                            <MdOutlineMail
                              className="ml-2"
                              style={{
                                color: "#020671",
                              }}
                            />
                          </div>
                          <div>
                            <span className="ml-2" style={{ fontSize: "14px" }}>
                              {/* {formData.email} */}
                              {
                                showViewbtn !== 0 ? (
                                  <span className="blur_css ml-2" style={{ fontSize: "14px" }}>XXXXXXXXXXX</span>
                                ) : (
                                  <span className="ml-2" style={{ fontSize: "14px" }}>{formData.email}</span>
                                )
                              }
                            </span>
                          </div>
                        </div>
                        <div className="mobile-resp">
                          {formData.website ? (
                            <>
                              {editKey === "website" ? (
                                <div className="d-inline-block">
                                  <div className="d-flex align-items-start">
                                    <div>
                                      <Controller
                                        name="website"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: `website is required`,
                                        }}
                                        render={({ field }) => (
                                          <input
                                            placeholder="Enter website"
                                            type="text"
                                            {...field}
                                            className="sml-inp"
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.website &&
                                          errors.website.message}
                                      </FormHelperText>
                                    </div>
                                    <Button type="submit">
                                      <DoneOutlineIcon
                                        sx={{
                                          fontSize: "16px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <span>
                                  <CiGlobe
                                    className="mx-1"
                                    style={{
                                      color: "#020671",
                                    }}
                                  />
                                  <span
                                    className="ml-2 mr-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {formData.website}
                                  </span>
                                  {editBtn === true && (
                                    <EditIcon
                                      sx={{
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEditBtn("website")}
                                    />
                                  )}
                                </span>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {showViewbtn !== 0 && (
                        <div className="text-right mt-3">
                          <Button
                            variant="outlined"
                            className="edit-btn-profile"
                            sx={{
                              textTransform: "none",
                              border: "1px solid",
                              borderColor: "#020671",
                              color: "#020671",
                              padding: "6px 30px",
                            }}
                            onClick={handlemaskClickOpen}
                          >
                            View Contact
                          </Button>
                        </div>
                      )}
                      {userProfileId === 0 && (
                        <div className="profile-btn  mt-3">
                          <div className="mr-3 edit-btn-res">
                            {editBtn === false && (
                              <Button
                                variant="outlined"
                                className="edit-btn-profile"
                                sx={{
                                  textTransform: "none",
                                  border: "1px solid",
                                  borderColor: "#020671",
                                  color: "#020671",
                                  padding: "6px 20px",
                                }}
                                onClick={handleOpenEdit}
                              >
                                Edit Profile
                              </Button>
                            )}
                            {editBtn === true && (
                              <Button
                                variant="outlined"
                                className="edit-btn-profile"
                                sx={{
                                  textTransform: "none",
                                  border: "1px solid",
                                  borderColor: "#020671",
                                  color: "#020671",
                                  padding: "6px 20px",
                                }}
                                type="submit"
                              >
                                Save Profile
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="pb-5">
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="profile-box-filter">
                  <List
                    style={{
                      border: "1px solid gray",
                      padding: "0px",
                      display: "grid",
                    }}
                    className="Lists-prof-det"
                  >
                    <NavLink
                      to={"/investor/profile"}
                      className={`custom-button`}
                    >
                      Profile
                    </NavLink>
                    <NavLink
                      to={"/investor/startup"}
                      className={`custom-button`}
                    >
                      Startups
                    </NavLink>
                    <NavLink to={"/investor/team"} className={`custom-button`}>
                      Teams
                    </NavLink>
                    <NavLink
                      to={"/investor/event-session"}
                      className={`custom-button`}
                    >
                      Events & Notifications
                    </NavLink>
                    <NavLink
                      to={"/investor/support-deck"}
                      className={`custom-button`}
                    >
                      Support Deck
                    </NavLink>
                    <NavLink
                      to={"/investor/news-media"}
                      className={`custom-button`}
                    >
                      News & Media
                    </NavLink>
                  </List>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Outlet></Outlet>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={smartOpen}
          onClose={availsmartClose}
        >
          <DialogContent>
            <div className="text-center">
              <img src={'/images/Profile/sucess.png'} alt="" className="sucess-image" />
              <h4>Thank you for Requesting...</h4>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      {/* Qr-modal */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={qrModalopen}
        onClose={handleModalClose}
      >
        <DialogContent>
          <Qrcode path={path} />
        </DialogContent>
      </Dialog>
      <div>
        <MaskDialog />
      </div>
    </div>
  );
};

export default InvestorProfileLayout;

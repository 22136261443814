import React, { useContext } from 'react'
import AdminSupportFilters from './AdminSupportFilters'
import { Autocomplete, Box, FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, Pie, PieChart } from 'recharts';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import ApexCharts from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import DataContext from '../../../context/DataContext';

const data = [
    { name: 'Startuptn', uv: 20 },
    { name: 'ITINT', uv: 22 },
    { name: 'TANCAM', uv: 35 },
    { name: 'TANCOE', uv: 30 },
    { name: 'Fort', uv: 25 },
    { name: 'SIDCO', uv: 28 },
    { name: 'TNGCC', uv: 24 },
    { name: 'EDII', uv: 35 },
    { name: 'TANSOE', uv: 27 },
    { name: 'TNFMTC', uv: 26 },
    { name: 'InnovateTN', uv: 23 },
    { name: 'TICEL', uv: 29 },
];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '40px' }}>
                <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const choose = [
    'Option 1',
    'Option 2'
]

const AdminSupportDashboard = () => {
    const { supportDepartmentId, setPageLoading, hasPrivilege } = useContext(DataContext);

    const maxValue = Math.max(...data.map(item => item.uv));
    const [formData, setFormData] = useState('')
    const { handleSubmit, control, setValue, reset, setError, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });


    const [chartData, setChartData] = useState({
        series: [44, 55],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                },
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: 'gradient',
            },
            legend: {
                show: false,
            },
            // legend: {
            //     formatter: (val, opts) => {
            //         return `${val} - ${opts.w.globals.series[opts.seriesIndex]}`;
            //     },
            // },
            // title: {
            //     text: 'Gradient Donut with custom Start-angle',
            // },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                            show: false,
                        },
                    },
                },
            ],
            colors: ['#ff0000', '#4623e9']
        },
    });


    return (
        <>
            <AdminSupportFilters />
            <section>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item sm={12} md={6} lg={8}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-new">
                                    <p>Total Support Raised</p>
                                    <h3 className="bluee-clrr">43</h3>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-new">
                                    <p>Closed</p>
                                    <h3 className="bluee-clrr">32</h3>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-new">
                                    <p>Unsolved</p>
                                    <h3 className="bluee-clrr">11</h3>
                                </Box>
                            </Grid>
                        </Grid>

                        <div className='bar-graph'>
                            <div className='bar-head'>
                                <span>
                                    <h5>Total Ticket Raised across departments</h5>
                                    <span className='bar-chart'>Monthly</span>
                                </span>
                                <div className='support-dashboard-close mb-5'>
                                    <Controller
                                        sx={{ width: '200' }}
                                        name="category"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Required" }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={choose}                                                
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Choose " />
                                                )}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.category && errors.category.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={data}>
                                    <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar dataKey="uv" fill="#8884d8" radius={[10, 10, 0, 0]}>
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.name === 'EDII' ? '#0088FE' : '#C4C4C4'} />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div>
                            <p className='my-4'>Support Ticket</p>
                            <TableContainer className='table-bg' component={Paper}>
                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                                    <TableHead>
                                        <TableRow className='bar'>
                                            <TableCell>Ticket No.</TableCell>
                                            <TableCell>Organization Name</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Due Date</TableCell>
                                            <TableCell align='center'>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ position: 'relative' }}>
                                        {/* {supportList.map((list, i) => ( */}
                                        <>
                                            <TableRow
                                                sx={{
                                                    position: 'relative',
                                                    '&:after': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        border: '1px solid rgba(224, 224, 224, 1)',
                                                        borderRadius: '20px',
                                                        pointerEvents: 'none',
                                                        marginBottom: '-1px',
                                                    },
                                                    // '& > *': {
                                                    //     padding: '10px',
                                                    //     backgroundColor: '#fff',
                                                    //     '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                                                    // },
                                                    marginBottom: '10px',
                                                }}
                                                // key={i}
                                                className="Sup-list"
                                            // onClick={() => handleDetails(list.id)}
                                            >
                                                <TableCell className='list-1'>
                                                    <span className='list-1-clr'>SPT876119</span>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h6" className="list-2-clr">AuraLab Pvt.Ltd</Typography>
                                                    <div className="list-2-dflex">
                                                        <span className="list-2-grey">User Profile:</span>
                                                        <span className="list-2-black">Startups</span>
                                                    </div>
                                                    <div>
                                                        <span className="list-2-grey">Smartcard ID:</span>
                                                        <span className="list-2-black">STN0001</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <span className='list-4-clr'>Funding Support</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className={`list-5-clr`}>2 days</span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <span className='list-6-clr'>Assigned</span>
                                                </TableCell>
                                            </TableRow>
                                            <span className='mt-2 mr-3'>{' '}</span>
                                        </>
                                        {/* ))} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <div className='donut-chart'>
                            <h5 className='mb-5'>Total Unsolved Ticket</h5>
                            {/* <img src='/images/donut-graph.png' alt='' className='img-fluid' /> */}
                            <div id="chart">
                                <ReactApexChart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="donut"
                                    width={300}
                                />
                            </div>
                            <div id="html-dist"></div>
                        </div>

                        <div className='unsolved-tic'>
                            <h5 className='my-3'>Unsolved TIckets</h5>
                            <Box className="widget-red-box">
                                <h3 className="red-clrr">84</h3>
                                <p className="red-clrr-1">Currently</p>
                            </Box>
                            <p>By Category</p>
                            <div className='dept-css'>
                                <p>Department 1</p>
                                <p>54</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 2</p>
                                <p>24</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 3</p>
                                <p>08</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 4</p>
                                <p>08</p>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </section >
        </>
    )
}

export default AdminSupportDashboard
import React, { createContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import apiService from '../api/apiService';
import PropTypes from "prop-types";
import cryptodecryptJS from '../hooks/cryptodecryptJS';
const today = dayjs().add(1, 'day');
const DataContext = createContext({})
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const DataProvider = ({ children }) => {
    const navigator = useNavigate()
    // password icon code
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };


    // form page code
    function getStyles(name, field, theme) {
        return {
            fontWeight:
                field.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const VisuallyHiddenInput = styled('input')`
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        width: 1px;
    `;
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }));


    // accordion code
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: ` 1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            marginBottom: 10,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`custom-tabpanel-${index}`}
                aria-labelledby={`custom-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const [yearListValue, setYearListValue] = useState([])
    const yearList = []
    const yearListApi = () => {
        let year = new Date().getFullYear()
        for (let i = year; 1978 < i; i--) {
            var val = i.toString()
            yearList.push(val)
        }
        setYearListValue(yearList)
    }
    const [durationListValue, setDurationListValue] = useState([])
    const durationList = []
    const durationListApi = () => {
        for (let i = 1; i <= 60; i++) {
            var val = i.toString()
            durationList.push(val)
        }
        setDurationListValue(durationList)
    }
    const validatePincode = (value) => {

        if (/^(\d)\1{5}$/.test(value)) {
            return 'Pincode cannot be a sequence of six identical digits';
        }
        return true;
    };
    const validatePassword = (value) => {
        const trimmedValue = value.trim();
        // Check if the trimmed value is empty
        if (trimmedValue.length === 0) {
            return "Empty Space Not Allow";
        }
        if (trimmedValue.length > 15) {
            return "Password cannot exceed 15 characters";
        }
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!regex.test(value)) {
            return 'Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character,Empty Space Not Allow'
        }
    }
    //No white space pattern
    const isWhitespace = (value) => {
        return /^\s*$/.test(value);
    };
    const validateEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailRegex.test(value);
        return isValid || 'Invalid email address';
    };
    const validatePhoneNumber = (value) => {
        // const isValid = value.length === 10;
        if (value.length !== 10) {
            return 'Phone number must be 10 digits';
        }
        const matches = value.match(
            /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
        );
        if (matches === null) {
            return "Only numbers allowed";
        }
    };
    const validateNumberonly = (e) => {
        const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!/[0-9]/.test(e.key) && !validKeys.includes(e.key)) {
            e.preventDefault();
        }
    };
    const validateAadharNumber = (value) => {
        if (value.length) {
            if (value.length !== 12) {
                return 'Aadhar number must be 12 digits';
            }
        }
    }
    const validatePANNumber = (value) => {
        const panRegex = /^[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]$/;
        const isValid = panRegex.test(value);
        return isValid || 'Please enter a valid PAN number';
    }
    const checkAlphanumeric = (e) => {
        if (!/[0-9A-Za-z]/.test(e.key)) {
            e.preventDefault();
        }
    };
    const checkUppernumeric = (e) => {
        if (!/[0-9A-Za-z]/.test(e.key)) {
            e.preventDefault();
            return;
        }
    };
    const validateCinOrPanNo = (value) => {
        if (value.length !== 21) {
            return "Input must be exactly 21 characters long.";
        }
        return true;
    };

    const CurrentDate = () => {
        var today = new Date()
        var month = today.getMonth() + 1
        var date = today.getDate()
        if (month < 9) {
            month = 0 + month
        }
        if (date < 9) {
            date = 0 + date
        }
        return today.getFullYear() + '-' + month + '-' + date;
    }
    const tableIndexValue = (listSize, pageNumber, index) => {
        return listSize * pageNumber + index + 1
    }
    const [progress, setProgress] = useState(5);
    const handleNextClick = (value) => {
        if (progress < 100) {
            setProgress(value);
        }
    };
    const handleHomePage = () => {
        navigator('')
        currentPathRemove()
    }
    const currentPathRemove = () => {
        // localStorage.setItem('email', data.email)
        localStorage.removeItem('currentPath');
    }

    const fastUrlPath = () => {
        var profileupdated = localStorage.getItem("profile")

        var path = localStorage.getItem('way')
        var email = localStorage.getItem('email')
        if (path) {

            if (profileupdated === 'true') {

                localStorage.removeItem('currentPath');
                var conferenceurl
                if (path === 'Conference') {
                    // conferenceurl = `https://startuptn.getkameleon.com/conference?email=${cryptodecryptJS(email)}`
                    conferenceurl = `https://event.startuptn.in/conference?email=${cryptodecryptJS(email)}`
                } else {
                    // conferenceurl = `https://startuptn.getkameleon.com/authorize/login?email=${cryptodecryptJS(email)}`
                    conferenceurl = `https://event.startuptn.in/authorize/login?email=${cryptodecryptJS(email)}`
                }
                localStorage.setItem("conferencePath", conferenceurl)
                localStorage.removeItem('way')
                window.location.href = conferenceurl;
                navigator("/fest");
            } else {

                setProfileDialog(true);
            }

        } else {

            var currentPath = localStorage.getItem('currentPath')
            navigator(currentPath)
        }

    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [searchInputValue, setSearchInputValue] = useState("");
    // startup profile support ticket list
    const [supportList, setSupportList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const supportistApi = () => {
        setSupportList([])
        var req = {
            searchString: searchInputValue,
            listSize: 6,
            pageNumber: page,
        };
        apiService('support/ticket/list', req, "post")
            .then((result) => {
                if (result && result.data) {
                    if (result.data.responseDTOList) {
                        setSupportList(result.data.responseDTOList);
                        setTotalPage(result.data.totalPages)
                    }
                }
            })
            .catch((err) => { });
    };

    // admin support page code
    const [supportType, setSupportType] = useState('')
    const [readStatus, setReadStatus] = useState('')
    const [searchSupportInputValue, setSearchSupportInputValue] = useState("");
    const [selectedSupportPeriod, setSelectedSupportPeriod] = useState("All")
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [sortBy, setSortBy] = useState(true)
    const [supportDepartmentId, setSupportDepartmentId] = useState(0);
    const getSupportStatus = (value) => {
        if (value === 0) {
            return 'Not assigned';
        } else if (value === 1) {
            return 'Assigned';
        } else if (value === 2) {
            return 'Inprogress';
        } else if (value === 3) {
            return 'Closed';
        }
    };

    // const linkedInPattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9-]+\/?$/;
    const linkedInPattern = /^https:\/\/in\.linkedin\.com\/company\/([a-zA-Z0-9-]+)\/?$/;

    // user profile code 
    const [para, setPara] = useState("");
    const [formData, setFormData] = useState({});
    const [upcoming, setUpComing] = useState([]);
    const [rating, setRating] = useState([]);
    const [investorpara, setInvestorPara] = useState("");
    const [userProfileId, setUserProfileId] = useState(0)
    const [userProfileParsona, setUserProfileParsona] = useState('')
    const [showViewbtn, setShowViewbtn] = useState(1)
    const [showUidNumber, setShowUidNumber] = useState("")
    const [smartStatus, setSmartStatus] = useState("")
    const getApi = () => {
        var userProfileIds = Number(localStorage.getItem("userpersonaId"));
        var userProfileParsonas = localStorage.getItem("userpersona")
        // localStorage.setItem("userpersona", userpersona);
        setFormData([])
        setPara('')
        setUpComing([])
        setRating([])
        setInvestorPara('')
        setPageLoading(true);
        let id
        let type
        if (userProfileIds !== 0) {
            id = userProfileIds
            type = userProfileParsonas
            setUserProfileId(id)
        } else {
            id = localStorage.getItem("id");
            type = localStorage.getItem('type')
            setUserProfileId(0)
            setShowViewbtn(0)
        }

        apiService(`userprofile/get?persona=${type}&userid=${id}`, "", 'get')
            .then((result) => {
                setPageLoading(false);
                if (result && result.data) {
                    var data = result.data;
                    if (data.imageName) {
                        data.imageUrl = data.imageName
                    }
                    if (data.phone) {
                        data.phone = cryptodecryptJS(data.phone)
                    }
                    if (data.email) {
                        data.email = cryptodecryptJS(data.email)
                    }
                    if (data.about) {
                        setPara(data.about);
                    }
                    if (data.upcomingSessions) {
                        setUpComing(data.upcomingSessions);
                    }
                    if (data.ratings) {
                        setRating(data.ratings);
                    }
                    if (data.about) {
                        setInvestorPara(data.about);
                    }
                    if (data.uid) {
                        setShowUidNumber(data.uid)
                    }
                    if (data.smartcardstatus) {
                        setSmartStatus(data.smartcardstatus)
                        console.log(data.smartcardstatus, "smartstatus");
                    }

                    if (data.founders) {
                        data.founders.forEach(founder => {
                            if (founder.email) {
                                founder.email = cryptodecryptJS(founder.email);
                            }
                            if (founder.phone) {
                                founder.phone = cryptodecryptJS(founder.phone);
                            }
                            founder.emailVerify = true
                        });
                    }
                    setFormData(data);
                }
            })
            .catch((err) => { });
    };
    const [editBtn, setEditBtn] = useState(false);
    const handleOpenEdit = () => {
        setEditBtn(true);
    };
    const [editKey, setEditKey] = useState("");
    const handleEditBtn = (key) => {
        setEditKey(key);
    };
    const removeItem = () => {
        localStorage.removeItem("userpersonaId");
        localStorage.removeItem("userpersona");
    }
    const hasPrivilege = (requiredLevel) => {
        var val = localStorage.getItem("privileges")
        if (val !== 'undefined' && val !== null) {
            const privilegeLevels = JSON.parse(val);
            return privilegeLevels.indexOf('0') < privilegeLevels.indexOf(requiredLevel);
        }

    };
    //captcha state
    const [captchaValue, setCaptchaValue] = useState(false)
    const [captcha, setCaptcha] = useState('');

    // Mask Dialog Code
    let jwttoken = localStorage.getItem("jwttoken");

    const [redirectPath, setRedirectPath] = useState(null);

    const [maskshow, setMaskshow] = useState(false);

    const handlemaskClickOpen = () => {
        if (jwttoken) {
            setMaskshow(true);
        } else {
            const currentPath = window.location.pathname;
            localStorage.setItem('currentPath', currentPath);
            navigator('/login')
        }

    };

    const handlemaskClose = () => {
        setMaskshow(false);
    };
    /// generateCaptcha
    const [inputValue, setInputValue] = useState('');
    const generateCaptcha = () => {
        setCaptchaValue(null)
        setInputValue('')
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const captchaLength = 6;
        let randomCaptcha = '';
        for (let i = 0; i < captchaLength; i++) {
            const randomNumber = Math.floor(Math.random() * chars.length);
            randomCaptcha += chars.substring(randomNumber, randomNumber + 1);
        }
        setCaptcha(randomCaptcha);
    };


    // Book ticket dialog

    const [ProfileDialog, setProfileDialog] = useState(false)
    const handleProfileDialogOpen = () => {
        setProfileDialog(true);
    };
    const handleProfileDialogClose = () => {
        setProfileDialog(false);
    };

    const [ProfileDialogBox, setProfileDialogBox] = useState(false)
    const handleProfileDialogOpenBox = () => {
        setProfileDialogBox(true);
    };
    const handleProfileDialogCloseBox = () => {
        setProfileDialogBox(false);
    };

    const [showPopup, setShowPopup] = useState(false);
    const sessionTimeout = 30 * 60 * 1000; // 30 minutes
    const warningTime = 5 * 60 * 1000; // 5 minutes before timeout
    // const sessionTimeout = 1 * 60 * 1000; // 1 minute
    // const warningTime = 10 * 1000; // 10 seconds before timeout
    var token = localStorage.getItem("jwttoken");
    var session = sessionStorage.getItem('session')
    const checkSessionTimout = () => {
        if (token && session === null) {
            sessionStorage.setItem('session', true)
            const timeoutId = setTimeout(() => {
                setShowPopup(true);
            }, sessionTimeout - warningTime);
            console.log(timeoutId);

            return () => clearTimeout(timeoutId); // Cleanup on component unmount
        }
    }

    // Google Dialog
    const [openModal, setOpenModal] = useState(false);

    const [idValue, setIdValue] = useState(0)
    var profupd = localStorage.getItem('profile')
    const handleParsonaClose = (value) => {
        if (profupd === "false") {
            if (value === 'STARTUP') {
                navigator('/startups/basicinfo')
            } else if (value === 'ASPIRANT') {
                navigator('/aspirants/basicinfo')
            } else if (value === 'MENTOR') {
                navigator('/mentors/basicinfo')
            } else if (value === 'INNOVATOR') {
                navigator('/innovators/basicinfo')
            } else if (value === 'INVESTOR') {
                navigator('/investor/basicinfo')
                // navigator('/investor/profile')
            } else if (value === 'STARTUP-ECO') {
                navigator('/ecosystem/basicinfo')
            } else if (value === 'STARTUPS-SERVPROV') {
                navigator('/service-provider/basicinfo')
            } else if (value === 'CORE-TEAM') {
                navigator('/admin')
            }
        } else {
            if (value === 'STARTUP') {
                navigator('/startups')
            } else if (value === 'ASPIRANT') {
                navigator('/aspirants')
            } else if (value === 'MENTOR') {
                navigator('/mentors')
            } else if (value === 'INNOVATOR') {
                navigator('/innovators')
            } else if (value === 'INVESTOR') {
                navigator('/investor')
            } else if (value === 'STARTUP-ECO') {
                navigator('/ecosystem')
            } else if (value === 'STARTUPS-SERVPROV') {
                navigator('/service-provider')
            }

        }



    }
    return (
        <>
            <DataContext.Provider value={{
                tableIndexValue, fullScreen, searchInputValue, setSearchInputValue, CustomTabPanel,
                validateEmail, validatePhoneNumber, validateNumberonly, pageLoading, setPageLoading,
                readyOnlyValue, setReadyOnlyValue, yearListValue, yearListApi, modalstyle, linkedInPattern,
                // page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, count, setCount,
                supportList, page, setPage, totalPage, setTotalPage, supportistApi,
                showPassword, handleClickShowPassword, handleMouseDownPassword, loading, setLoading, smartStatus, setSmartStatus,
                open, handleOpen, handleClose, VisuallyHiddenInput, CurrentDate,
                // form 1 code
                today, MenuProps, BootstrapInput, getStyles, captchaValue, setCaptchaValue, captcha, setCaptcha,
                navigator,
                durationListApi, durationListValue, handleParsonaClose,
                Accordion, AccordionSummary, AccordionDetails, icon, checkedIcon,
                validateAadharNumber, validatePANNumber, checkAlphanumeric, checkUppernumeric, validatePassword, investorpara, isWhitespace, validatePincode,
                validateCinOrPanNo, idValue, setIdValue,

                // this project code
                progress, handleNextClick, handleHomePage, hasPrivilege,
                // profile Mask Dialog code
                maskshow, setMaskshow, handlemaskClickOpen, handlemaskClose, redirectPath, setRedirectPath,
                // generateCaptcha
                inputValue, setInputValue, generateCaptcha,

                // bookticket dialog
                ProfileDialog, setProfileDialog, handleProfileDialogOpen, handleProfileDialogClose,
                ProfileDialogBox, setProfileDialogBox, handleProfileDialogOpenBox, handleProfileDialogCloseBox,
                // admin support list page code
                supportType, setSupportType, readStatus, setReadStatus, searchSupportInputValue, setSearchSupportInputValue,
                selectedSupportPeriod, setSelectedSupportPeriod, getSupportStatus, startDate, setStartDate, endDate, setEndDate, sortBy, setSortBy, supportDepartmentId, setSupportDepartmentId,
                //   user profile code
                getApi, para, formData, handleOpenEdit, editBtn, setEditBtn, handleEditBtn, editKey, setEditKey, rating, upcoming, userProfileId, setUserProfileId, userProfileParsona, setUserProfileParsona, removeItem
                , showViewbtn, setShowViewbtn, setShowUidNumber, showUidNumber,
                fastUrlPath, openModal, setOpenModal,
                showPopup, setShowPopup, checkSessionTimout,
            }}>
                {children}

            </DataContext.Provider>

        </>
    )
}
export default DataContext;
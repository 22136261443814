import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const TnfestThiruvizhabanner = () => {
  const [screenSize, setScreenSize] = useState(5);
  const thiruimages = [
    '/images/Banner/previous-year/1.webp',
    '/images/Banner/previous-year/2.webp',
    '/images/Banner/previous-year/3.webp',
    '/images/Banner/previous-year/4.webp',
    '/images/Banner/previous-year/5.webp',
    '/images/Banner/previous-year/6.webp',
    '/images/Banner/previous-year/7.webp',
    '/images/Banner/previous-year/8.webp',
    '/images/Banner/previous-year/9.webp',
    '/images/Banner/previous-year/10.webp',
    '/images/Banner/previous-year/11.webp',
    '/images/Banner/previous-year/12.webp',
    '/images/Banner/previous-year/13.webp',
    '/images/Banner/previous-year/14.webp',
    '/images/Banner/previous-year/15.webp',
    '/images/Banner/previous-year/16.webp',
    '/images/Banner/previous-year/17.webp',
    '/images/Banner/previous-year/18.webp',
    '/images/Banner/previous-year/19.webp',
    '/images/Banner/previous-year/20.webp',

  ];
  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(5);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(5);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(5);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(5);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(5);
    } else {
      setScreenSize(1);
    }
  };
  return (
    <div>
      <div className='cm-bg-img-tiru'>
        <div className="agenda-head">
          <h2>Startup Thiruvizha 2023</h2>
          <Swiper
            slidesPerView={screenSize}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="my-Swiper"
          >

            {thiruimages.map((image, index) => (
              <SwiperSlide key={index}>
                <div className='swip-crd-bg'>
                  <img src={image} alt={`Slide ${index + 1}`} className='foreground-image' />
                </div>
              </SwiperSlide>
            ))}

          </Swiper>
        </div>
      </div>
      
    </div>
  )
}

export default TnfestThiruvizhabanner
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import { Autocomplete, Button, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, TextField } from '@mui/material';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Delegatefreepass from '../../home-module/home-page/Tn_fest/PassDialog/Delegatefreepass';

const UpdatePersona = () => {
    const { navigator,setPageLoading, handleHomePage, handleParsonaClose, setProfileDialogBox, setProfileDialog } = useContext(DataContext)
    const { handleSubmit, control, setValue, clearErrors, setError, reset, formState: { errors } } = useForm();
    const { id } = useParams()

    const idValue = id
    useDidMountEffect(() => {
        profileGet()
    }, [])
    const [profileType, setProfileType] = useState([])
    const profileGet = () => {
        axios.get('/json/profiledetails.json', '').then((result) => {
            if (result.data) {
                setProfileType(result.data)
            }
        }).catch((err) => {

        });
    }
    const [profileRoleList, setProfileRoleList] = useState([])
    const handleSelectProfileType = (value) => {
        setValue('persona', value)
        clearErrors('persona')

        var val = profileType.filter((list) => list.profileName === value)
        setProfileRoleList(val[0].role)
        setValue('role', '')
        setError('role', 'Role is required')
    }

    const handleSelectProfileRole = (value) => {
        setValue('role', value)
        clearErrors('role')
    }
    const login = () => {
        var google = sessionStorage.getItem('GoogleType')
        var uid = sessionStorage.getItem('Uid',)
        var req = {
            "type": google,
            "uid": uid
        }
        apiService('login', req, 'unauthpost').then((res) => {
            // localStorage.setItem('continueWith', true)
            localStorage.setItem('jwttoken', res.data.token)
            debugger
            const type = localStorage.getItem('type');
            const path = localStorage.getItem('way')
            debugger
            if (path === 'Conference') {
                setProfileDialogBox(true)
                
            } else {
                // handleParsonaClose(type);
                navigator('/fest')
            }
        })
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        var type = profileType.filter((list) => list.profileName === data.persona)
        data.persona = type[0].profileType
        var role = profileRoleList.filter((list) => list.roleName === data.role)
        data.role = role[0].roleValue
        data.id = atob(idValue)
        console.log(data.id, "idvalue")
        debugger
        apiService('user/register/persona/update', data, "post").then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    localStorage.setItem('role', data.role)
                    localStorage.setItem('type', data.persona)
                    reset()
                    notifyService("success", "Success", "");
                    localStorage.removeItem('jwttoken')
                    debugger
                    login()
                }
            }
        });
    }
    const onError = (data) => {
        notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
    }

    return (
        <div>
            <div className='signup-details-bg-dev' style={{ marginBottom: '50px' }}>
                {/* <img src="/images/TamilNadu_Logo.svg" alt="" className="img-fluid startup_img2" onClick={handleHomePage}/> */}
                <img src='/images/startup-white.png' alt='' className='startup_img img-fluid' onClick={handleHomePage} />
            </div>
            <Grid container spacing={2} className='p-4'>
                <Grid item xs={12}>
                    <div className=''>
                        {/* <Button startIcon={<ArrowBackIcon />} onClick={backBtn}>Back</Button> */}
                    </div>
                </Grid>
                <Grid item lg={4} md={4} sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className='signup-data_div1'>
                                <div className='signup-title1'>
                                    <p>Let's Start</p>
                                </div>
                                <div className='signup_box_div'>
                                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                                        <Grid container spacing={1}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                <Controller name="persona" control={control} defaultValue="" rules={{ required: `Profile Type is required` }}
                                                    render={({ field }) =>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            {...field}
                                                            options={profileType.map((val => val.profileName))}
                                                            onChange={(e, selectedOptions) =>
                                                                handleSelectProfileType(selectedOptions)
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Select Profile Type  " />
                                                            )}
                                                        />} />
                                                <FormHelperText className='text-danger'>{errors.persona && errors.persona.message}</FormHelperText>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>

                                                {
                                                    profileRoleList.length > 0 && (
                                                        <div >
                                                            <Controller name="role" control={control} defaultValue="" rules={{ required: `Role is required` }}
                                                                render={({ field }) =>
                                                                    <Autocomplete
                                                                        disableClearable
                                                                        disablePortal
                                                                        {...field}
                                                                        options={profileRoleList.map((val => val.roleName))}
                                                                        onChange={(e, selectedOptions) =>
                                                                            handleSelectProfileRole(selectedOptions)
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Select Profile Role  " />
                                                                        )}
                                                                    />} />
                                                            <FormHelperText className='text-danger'>{errors.role && errors.role.message}</FormHelperText>
                                                        </div>
                                                    )
                                                }
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12} className='d-flex align-item-center'>
                                                <div className='ml-auto'>
                                                    <button className='register-btn' type="submit">Continue</button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={2} sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
                </Grid>


            </Grid>
            <Delegatefreepass />
        </div>
    )
}

export default UpdatePersona
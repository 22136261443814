import { Button, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FaPlay, FaPause } from "react-icons/fa6";

const TnfestVideo = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleVideoEnd = () => {
        setIsPlaying(false);
      };

      videoElement.addEventListener('ended', handleVideoEnd);

      return () => {
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, []);
  return (
    <div>
      {/* <div className='strip-img-div'>
        <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='strip-img' />
      </div> */}
      <div className='video-bg'>
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="containerv1">
              <div className="gradient-background">
                {!isPlaying ? <img src="/images/Banner/video-template.webp" className="image-thumbnail" alt="thumbnail-image" /> : <></>}

                <video ref={videoRef} className="video">
                  <source src="/images/Banner/vid-1.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <button className="play-button" onClick={handlePlayPause}>
                  {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div>
              <h2 className='video-heading mb-3'>Startup Thiruvizha</h2>
              <p className='video-para'>{`‘Tamilnadu Startup Thiruvizha’ is a two-day annual celebration of Startups and
              Entrepreneurship in Tamil Nadu. Enlightening panel discussions, keynote addresses and
              fireside chats by industry experts and successful Startup founders, B2B meetings, spaces
              for interaction and cultural events will be part of the celebration.`}</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='img-fluid' />
    </div>
  )
}

export default TnfestVideo




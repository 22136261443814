import React, { useContext, useState } from "react";
import { Container, Grid, TextField, Autocomplete, FormControl, FormHelperText, Pagination, } from "@mui/material";
import { Typewriter } from "react-simple-typewriter";
import { Controller, useForm } from "react-hook-form";
import { MdArrowDropDownCircle } from "react-icons/md";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { MdMailOutline } from "react-icons/md";
import { FaCircleArrowRight } from "react-icons/fa6";
import { RiCloseCircleFill } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import apiService from "../../api/apiService";
// import { useEffect } from "react";
// import Norecordsfound from "../common-pages/Norecordsfound";
import Navbar from "../navbar-page/Navbar";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";
import Footer from "../footer-page/Footer";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";

const Team = () => {
    const { setPageLoading, navigator, setUserProfileId, setUserProfileParsona, setShowViewbtn, showViewbtn } = useContext(DataContext);
    const [totalPage, setTotalPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState();
    const [sectorId, setSectorId] = useState();
    const [districtId, setDistrictId] = useState();
    const [hubDistrictId, setHubDistrictId] = useState(0);
    const [selectedOption, setSelectedOption] = useState("DPIIT Startup");
    const [page, setPage] = useState(1);
    const [inchargeSectorId, setInchargeSectorId] = useState()
    useDidMountEffect(() => {
        // userlistApi();
    }, [searchInputValue, sectorId, districtId, hubDistrictId, selectedOption, page,]);
    const [sectorList, setSectorList] = useState([]);
    const [sectorval, setSectorval] = useState({});
    const [inchargemail, setInchargemail] = useState("")

    const userlistApi = () => {
        setSectorList([]);
        setPageLoading(true);
        var data = {
            listSize: 12,
            pageNumber: page,
            searchString: searchInputValue,
            sectorId: sectorId,
            districtId: districtId,
            hubId: hubDistrictId,
            role: selectedOption,
        };
        apiService("ecosystem/list", data, "unauthpost")
            .then((result) => {
                setPageLoading(false);
                if (result) {
                    if (result.data) {
                        setInchargeSectorId(result.data.sectorId)
                        if (result.data.sector) {
                            setSectorval(result.data.sector);
                            if (result.data.sector.inchargEmail) {
                                var emailincharge = result.data.sector.inchargEmail
                                setInchargemail(cryptodecryptJS(emailincharge))
                            }
                        }
                        setTotalPage(result.data.totalPages);
                        if (result.data.userProfiles) {
                            setSectorList(result.data.userProfiles);

                        }
                    }
                }
            })
            .catch((err) => { });
    };


    const team = [
        {
            "img": "/images/team/dummy.jpg",
            "name": "Dinesh Kumar S",
            "position": "Vice President, Incubation Initiatives",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/sivakumar.png",
            "name": "Sivakumar	Palanisamy",
            "position": "Vice President, Regional Hubs",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Rajan B L",
            "position": "Vice President, Venture Lab & Strategy",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Sai Ranganathan",
            "position": "Associate Vice President, Investments",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/nikunj.png",
            "name": "Nikunj	Panchal",
            "position": "Associate Vice President, Investments",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/Stalin.png",
            "name": "Stalin	Jacob J",
            "position": "Associate Vice President, Regional Hubs",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/ponni.png",
            "name": "Ponni Velmurugan",
            "position": "Associate Vice President, Facilities",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/vinoth.png",
            "name": "Vinoth	Rajendran",
            "position": "Project Lead, Madurai Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Akila S",
            "position": "Project Lead, Govt Interface",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/vijayalak.png",
            "name": "Vijayalakshmi Ganesan",
            "position": "Project Lead, Venture Lab",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/Hari.png",
            "name": "Hari Jayanth",
            "position": "Associate Vice President, Support Centre",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/rahul.png",
            "name": "Rahul N",
            "position": "Project Lead, Tirunelveli Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/ram.png",
            "name": "Ramajeyam R M J",
            "position": "Project Lead, Investments",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/magesh.png",
            "name": "Mageswar R",
            "position": "Associate Vice President, Market Access",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/arasan.png",
            "name": "Senthamil Arasan A",
            "position": "Project Lead, Cuddalore Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/13.png",
            "name": "Sakthivel Kaliappan",
            "position": "Project Lead, Erode Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/14.png",
            "name": "Suganya Selvaraj",
            "position": "Project Lead, Investments",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Siva Sankaran R",
            "position": "Associate Vice President, MarCom",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/15.png",
            "name": "Venkadeswari N",
            "position": "Project Lead",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/16.png",
            "name": "Ezhilarasan S",
            "position": "Project Lead, Support Centre",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/17.png",
            "name": "Gautham Palaniswamy",
            "position": "Project Lead, Incubation Initiatives",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/18.png",
            "name": "Ragesh	U K",
            "position": "Project Associate, Investments",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/19.png",
            "name": "Balasubramanian S",
            "position": "Project Associate, Investments & Tech",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Karuppanan	P",
            "position": "Project Lead, Madurai Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Rajsekar Sivasamy",
            "position": "Project Associate, Coimbatore Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/21.png",
            "name": "Gowtham B",
            "position": "Project Associate, Support Centre",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/22.png",
            "name": "S R Aneesh",
            "position": "Project Lead, Incubation Initiatives",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/20.png",
            "name": "Selvam	Sekar",
            "position": "Project Associate, Investments",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/23.png",
            "name": "Gigin Durai C",
            "position": "Project Lead",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/24.png",
            "name": "Ajay Annamalai",
            "position": "Project Associate, Accounts",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/25.png",
            "name": "Gobinath Thangamuthu",
            "position": "Project Associate, Erode Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Vibith	S",
            "position": "Project Associate, Cuddalore Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Preethi Sivan",
            "position": "Project Associate, Incubation Initiatives",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/26.png",
            "name": "Udhayakumar Selvarasu",
            "position": "Project Associate, Market Access",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/27.png",
            "name": "Kaavya	Balasubramanian",
            "position": "Project Associate, MarCom",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/28.png",
            "name": "Dhivyabharathi	K",
            "position": "Project Lead",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/29.png",
            "name": "Umasankaran Panner Selvam",
            "position": "Project Associate, Trichy Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/30.png",
            "name": "Abarna	Renkarajan",
            "position": "Project Associate, Open Innovation",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/31.png",
            "name": "Saranya Suthandra Pandian ",
            "position": "Project Associate, Madurai Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/32.png",
            "name": "Nagathilagam M",
            "position": "Project Associate, Investments",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/33.png",
            "name": "Maheshkumar S",
            "position": "Project Associate, Thanjavur Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/34.png",
            "name": "Anirudh Gurumurthi",
            "position": "Project Associate, Market Access",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/35.png",
            "name": "Gayathri C",
            "position": "Project Lead, Coimbatore Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/36.png",
            "name": "Devanathan	Veerappan",
            "position": "Project Lead, MarCom",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/37.png",
            "name": "Prashanthi B",
            "position": "Project Lead, Incubation Initiatives",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/38.png",
            "name": "Subramanian N",
            "position": "Project Lead, Open Innovation",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/39.png",
            "name": "Naveen",
            "position": "Project Associate, Tech",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Renuka",
            "position": "Project Associate, Women Initiatives",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Prem",
            "position": "Vice President, Incubation Initiatives",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Suman D",
            "position": "Project Associate, Admin",
            "linkedin": "/images/team/linkedin.png"
        },

        {
            "img": "/images/team/40.png",
            "name": "Manoj",
            "position": "Project Associate, Coimbatore Office",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/41.png",
            "name": "Mohan",
            "position": "Project Associate, Erode Regional Hub",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/42.png",
            "name": "Jayagobi",
            "position": "Project Lead, MarCom",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/dummy.jpg",
            "name": "Gurushankar",
            "position": "Project Lead",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/43.png",
            "name": "Gopalakrishnan",
            "position": "Project Lead, MarCom",
            "linkedin": "/images/team/linkedin.png"
        },
        {
            "img": "/images/team/44.png",
            "name": "Ashwanth",
            "position": "Project Associate, Facilities",
            "linkedin": "/images/team/linkedin.png"
        },
    ]





    return (
        <div className="back-color1">
            <Navbar />
            <div className="back-layer"></div>
            <div className="back-layer1"></div>
            {/* <div className="top-layer"></div> */}
            <div >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12}>
                        <Container>
                            <div className="main-title">
                                <p className="team-heading mb-0">Meet our Team</p>
                                <div className="vectorlineimg">
                                    <img className="vector-line" src={"./images/Vector (1).png"} alt="vector" />
                                </div>

                                <p className="my-4" style={{ color: '#858585' }}>Meet our team of professional to serve you</p>
                            </div>
                        </Container>
                    </Grid>
                </Grid>

                <Container maxWidth="md">
                    <Grid
                        container
                        className="responsive-grid"
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <div className="container-team2">
                                <div className="background-box-top"></div>
                                <div className="background-team-img">
                                    <div className="team-circle">
                                        <img src="/images/team/min.png" className="team-people img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <div className="minis-text">
                                <h5 style={{ color: '#083FB2', fontWeight: 'bold' }}>Minister</h5>
                                <h5 style={{ fontWeight: 'bold' }}>Thiru. T.M. Anbarasan</h5>
                                <h6 style={{ color: '#8c8b8c' }}>Hon'ble Minister for Micro, Small, and </h6>
                                <h6 style={{ color: '#8c8b8c' }}>Medium Enterprises</h6>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <Container maxWidth="md">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <div className="sec-team" >
                                <div style={{ textAlign: 'center' }}>
                                    <h5 style={{ color: '#083FB2', fontWeight: 'bold' }}>Secretary to Government</h5>
                                    <h5 style={{ fontWeight: 'bold' }}>Tmt. Archana Patnaik, IAS</h5>
                                    <h6 style={{ color: '#8c8b8c' }}>Secretary to Government</h6>
                                    <h6 style={{ color: '#8c8b8c' }}>MSME Department</h6>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <div className="cc-img" >
                                <div className="container-team1">
                                    <div className="background-box-top"></div>
                                    <div className="background-team-img">
                                        <div className="team-circle">
                                            <img src="/images/team/ias.png" className="team-people img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>


                <div className="center-card">
                    <div className="container-team1">
                        <div className="background-box-top"></div>
                        <div className="background-team-img">
                            <div className="team-circle">
                                <img src="/images/team/sivaraj.png" className="team-people img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center my-5">
                    <h5 style={{ fontWeight: 'bold' }}>Thiru. Sivarajah Ramanathan</h5>
                    <h6 style={{ color: '#8c8b8c' }}>Mission Director & CEO</h6>
                    <h6 style={{ color: '#8c8b8c' }}>Tamil Nadu Startup & Innovation Mission (StartupTN)</h6>
                    <img src="/images/team/linkedin.png" className="img-fluid" alt="" />
                </div>


                <center><h3 style={{ color: '#083dab', fontWeight: 'bold' }}>Team</h3></center>


                <Container maxWidth="lg" className="my-5">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        {
                            team.length > 0 ? (
                                team && team.map((row, index) => (
                                    <Grid item xs={12} sm={12} md={3} lg={3} key={index} className="my-5" >
                                        <div className="container-team">
                                            <div className="background-box"></div>
                                            <div className="background-team2-img">
                                                <div className="team-circle2 text-center my-3">
                                                    <img src={row.img} className="team-people2 img-fluid" alt="" />
                                                </div>
                                                <p style={{ color: '#020671', fontSize: '14px', textAlign: 'center',fontWeight:'600' }}>{row.name}</p>
                                                <p style={{ color: '#00070B', fontSize: '10px', textAlign: 'center',}}>{row.position}</p>
                                                <div className="text-center"> <img src={row.linkedin} className="linkedin-size img-fluid" alt="" /></div>
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <></>
                            )
                        }


                    </Grid>
                </Container>


            </div>
            <Footer />
        </div>
    );
};

export default Team;

import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, InputAdornment, TextField, } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
// import DynamicForm from '../../common-pages/DynamicForm'
import { error } from "jquery";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import apiService from "../../../../api/apiService";
import cryptoJS from "../../../../hooks/cryptoJS";
import { Link } from "react-router-dom";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { auth, googleAuthProvider } from "../../Firebase";
import StartupIndia from "../../continue-with/StartupIndia";
import LinkedInLogin from "../../continue-with/LinkedInLogin";
// import useDidMountEffect from '../../hooks/useDidMountEffect'
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import VerifyPage from "./VerifyPage";
import ReCAPTCHA from "react-google-recaptcha";
import Captchatext from "../../../common-pages/Captchatext";
import notifyService from "../../../../api/notifySerivce";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Delegatefreepass from "../../../home-module/home-page/Tn_fest/PassDialog/Delegatefreepass";
const Signup = () => {
  const { navigator, validateEmail, validateNumberonly, setPageLoading, handleNextClick, setProfileDialogBox, validatePhoneNumber, validatePassword, fullWidth, captchaValue, setCaptchaValue } = useContext(DataContext);

  const { handleSubmit, control, watch, setError, clearErrors, formState: { errors }, } = useForm({ mode: 'onChange' });

  const [showPassword, setShowPassword] = React.useState(false);
  const [checkSamePassword, setCheckSamePassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showRePassword, setShowRePassword] = React.useState(false);


  const handleClickShowRePassword = () => setShowRePassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useDidMountEffect(() => {
    handleNextClick(10);
  }, []);

  const [formValid, setFormValid] = useState(false);

  const watchFields = watch(['firstName', 'email', 'phone', 'password', 'newPassword', 'agreeTerms']);

  useEffect(() => {
    const isFormValid = Object.values(watchFields).every(field => !!field);
    setFormValid(isFormValid);
  }, [watchFields]);
  const [emailVerify, setEmailVerify] = useState(null)
  const handleEmailVerfication = (value) => {
    setEmailVerify(null)
    if (value.length > 3) {
      var email = value.toLowerCase()
      apiService(`register/email/verify?email=${cryptoJS(email)}`, '', 'unauthget').then((result) => {
        if (result && result.data && result.data.responseStatus === 'Success') {
          setEmailVerify(true)
        } else {
          setEmailVerify(false)
          notifyService('danger', 'Error', result.data.responseMessage)
        }
      }).catch((err) => {
        setEmailVerify(null)
      });
    }
  }
  const onSubmit = (data) => {
    if (!captchaValue) {
      notifyService('danger', 'Enter Captcha', 'Please Verify  Captcha')
      return;
    }
    if (!emailVerify) {
      notifyService('danger', 'Error', 'Please Verify  Email')
      return;
    }
    if (data.password === data.newPassword) {
      setPageLoading(true);
      setCheckSamePassword(false);
      var getform = data;
      getform.persona = localStorage.getItem("type");
      getform.role = localStorage.getItem("role");
      getform.phone = cryptoJS(data.phone);
      getform.email = cryptoJS(data.email.toLowerCase());
      getform.password = cryptoJS(data.password);
      getform.newPassword = cryptoJS(data.newPassword);

      apiService("user/register", getform, "unauthpost").then((res) => {
        setPageLoading(false);
        if (res && res.data && res.data.responseStatus === "Success") {
          localStorage.setItem('continueWith', false)
          localStorage.setItem("logDetail", JSON.stringify(getform));
          navigator("/sign-up/verify");
        }
      });
    } else {
      setCheckSamePassword(true);
    }
  };

  const onError = (event) => {
    console.log("error Data:", event);
  };
  // continue With linked in
  const callBack = `${process.env.REACT_APP_LOGINCALLBACK}`;
  useDidMountEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      getToken(code);
    }
  }, [])
  const getToken = async (code) => {
    // const params = new URLSearchParams();
    // params.append('grant_type', 'authorization_code');
    // params.append('code', code);
    // params.append('redirect_uri', callBack);
    // params.append('client_id', '865drszkyuas84');
    // params.append('client_secret', 'mr8Bf0DzY3XElto2');
    // fetch('https://www.linkedin.com/oauth/v2/accessToken', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Access-Control-Allow-Origin': 'https://beta.startuptn.in'
    //   },
    //   body: params.toString(),
    // })
    //   .then(response => {
    //     if (!response.ok) {
    //       return response.json().then(errorInfo => Promise.reject(errorInfo));
    //     }
    //     return response.json();
    //   })
    //   .then(data => {
    //     console.log('Access token:', data);
    //     var getform = {
    //       "type": cryptoJS('LINKEDIN'),
    //       "token": cryptoJS(data),
    //       "persona": localStorage.getItem("type"),
    //       "role": localStorage.getItem("role"),
    //     }
    //     continueWith(getform)
    //   })
    //   .catch(error => {
    //     console.error('Error fetching the access token:', error);
    //   });
    var req = {
      "grantType": "authorization_code",
      "code": code,
      "redirectUri": callBack,
      "clientId": "865drszkyuas84",
      "clientSecret": "mr8Bf0DzY3XElto2"
    }
    apiService('user/linkedin/token', req, 'unauthpost').then((result) => {
      if (result && result.data && result.data.accessToken) {
        console.log('Access token:', result.data.accessToken);
        var getform = {
          "type": cryptoJS('LINKEDIN'),
          "token": cryptoJS(result.data.accessToken),
          "persona": localStorage.getItem("type"),
          "role": localStorage.getItem("role"),
        }
        continueWith(getform)
      }

    }).catch((err) => { });
  };
  // continue With startup india
  useDidMountEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const auth_token = urlParams.get('auth_token');
    if (auth_token) {
      console.log('startup india token', auth_token)
      var getform = {
        "type": cryptoJS('STARTUPINDIA'),
        "token": cryptoJS(auth_token),
        "persona": localStorage.getItem("type"),
        "role": localStorage.getItem("role"),
      }
      continueWith(getform)
    }
  }, [])
  // continue With google
  const handleGoogleSignIn = async () => {
    try {
      const result = await auth.signInWithPopup(googleAuthProvider);
      const user = result.user;
      if (user) {
        var getform = {
          "type": cryptoJS("GOOGLE"),
          "uid": cryptoJS(user.uid),
          "persona": localStorage.getItem("type"),
          "role": localStorage.getItem("role"),
        }
        continueWith(getform)
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const continueWith = (getform) => {
    apiService('login', getform, 'unauthpost').then((res) => {
      localStorage.setItem('continueWith', true)
      debugger
      responseCode(res, getform)
    })
  }
  // commen response Code
  const responseCode = (res, getform) => {
    if (res && res.data && res.data.token) {
      localStorage.setItem("logDetail", JSON.stringify(getform));
      if (res && res.data) {
        var type = localStorage.getItem('type')
        localStorage.removeItem('localStorage')
        localStorage.setItem('jwttoken', res.data.token)
        localStorage.setItem('userId', res.data.id)
        localStorage.setItem('id', res.data.id)
        localStorage.setItem('role', res.data.role)
        localStorage.setItem('email', res.data.email)
        localStorage.setItem('profile', res.data.profileUpdated)
        localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
        localStorage.setItem('name', res.data.firstName + res.data.lastName)
        localStorage.setItem('type', res.data.persona)
        if (res.data.persona === 'NA') {
          debugger
          navigator(`letsstart/${btoa(res.data.id)}`)
          localStorage.setItem('idValue', res.data.id)
        } else {
          const path = localStorage.getItem('way')
          if (path === 'Conference') {
            setProfileDialogBox(true)
          } 
          else {
            // if (type === 'STARTUP') {
            //   navigator('/startups')
            // } else if (type === 'ASPIRANT') {
            //   navigator('/aspirants')
            // } else if (type === 'MENTOR') {
            //   navigator('/mentors')
            // } else if (type === 'INNOVATOR') {
            //   navigator('/innovators')
            // } else if (type === 'INVESTOR') {
            //   navigator('/investor')
            // } else if (type === 'STARTUP-ECO') {
            //   navigator('/ecosystem')
            // } else if (type === 'STARTUPS-SERVPROV') {
            //   navigator('/service-provider')
            // }
            navigator('/fest')
          }

        }

      }
    }
  }
  let roleval = localStorage.getItem("role");
  const teamAndCondition = () => {
    navigator("/terms-condition");
  }
  const privacyPolicy = () => {
    navigator("/privacy-policy");
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCaptchaValue('')
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={1}>
        {roleval === "DPIIT" ? (
          <>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <StartupIndia />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <button className="continue_btn2" onClick={handleGoogleSignIn}>
                Continue with <img src="/images/logo/Google_logo.png" alt="" />
              </button>
            </Grid>
            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
              <LinkedInLogin />
            </Grid> */}
          </>
        ) : (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <button className="continue_btn2" onClick={handleGoogleSignIn}>
                Continue with <img src="/images/logo/Google_logo.png" alt="" />
              </button>
            </Grid>
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <LinkedInLogin />
            </Grid> */}
          </>
        )}

        {/* <Grid item lg={6} md={6} sm={6} xs={6}>
          <button className='continue_btn' onClick={handleGoogleSignIn}>Continue with <img src='/images/logo/Google_logo.png' alt='' /></button>
        </Grid> */}
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="signup-title">
              <p>Signup</p>
            </div>
            <div className="signup_box_div">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "First Name is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="First Name *"
                          placeholder="Enter First Name"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.firstName && errors.firstName)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.firstName && errors.firstName.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          fullWidth
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.lastName && errors.lastName.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Email Address is required",
                        validate: validateEmail,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Email Address *"
                          placeholder="Enter Email Address"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.email && errors.email)}
                          onInput={(e) => handleEmailVerfication(e.target.value)}
                          InputProps={{
                            endAdornment: emailVerify === true ? (
                              <InputAdornment position="end">
                                <CheckCircleIcon color="success" />
                              </InputAdornment>
                            ) : emailVerify === false ? (
                              <InputAdornment position="end">
                                <CloseIcon color="error" />
                              </InputAdornment>
                            ) : null,
                          }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        validate: validatePhoneNumber,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Phone Number *"
                          placeholder="Enter Phone Number"
                          fullWidth
                          type="text"
                          {...field}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 10, disabled: !emailVerify }}
                          error={Boolean(errors.phone && errors.phone)}

                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.phone && errors.phone.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Password is required", validate: validatePassword }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Password *"
                          placeholder="Enter Password"
                          fullWidth
                          {...field}
                          type={showPassword ? "text" : "password"}
                          inputProps={{ maxLength: 15, disabled: !emailVerify }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            )
                          }}
                          error={Boolean(errors.password && errors.password)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.password && errors.password.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="newPassword"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Enter Confirm Password", validate: validatePassword }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Confirm Password *"
                          placeholder="Confirm Password"
                          fullWidth
                          {...field}
                          type={showRePassword ? "text" : "password"}
                          inputProps={{ maxLength: 15, disabled: !emailVerify }}
                          InputProps={{
                            endAdornment: (
                              <IconButton onClick={handleClickShowRePassword}>
                                {showRePassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            )
                          }}
                          error={Boolean(errors.newPassword && errors.newPassword)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.newPassword && errors.newPassword.message}
                    </FormHelperText>
                    <FormHelperText className="text-danger">
                      {checkSamePassword === true ? "Password Mismatch" : <></>}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <div className="d-flex">
                      <Controller
                        name="agreeTerms"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Requried" }}
                        inputProps={{ maxLength: 15 }}
                        render={({ field }) => (
                          <FormControlLabel
                            {...field}
                            label=""
                            control={
                              <Checkbox
                                value={field.value}
                                checked={field.value}
                              />
                            }
                          />
                        )}
                      />
                      <p>
                        I agree all the StartupTN’s{" "}
                        <a className="c-blue" onClick={teamAndCondition}>
                          Terms & Condition
                        </a>
                        ,{" "}
                        <a className="c-blue" onClick={privacyPolicy}>
                          Privacy Policy
                        </a>{" "}
                        and default Notification Settings
                      </p>
                    </div>

                    <FormHelperText className="text-danger">
                      {errors.agreeTerms && errors.agreeTerms.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Captchatext />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="d-flex align-item-center"
                  >
                    <p className="mb-0">
                      Have an Account?-{" "}
                      <Link className="c-blue" to={"/login"}>
                        Login
                      </Link>
                    </p>
                    <button className={`ml-auto ${formValid ? 'register-btn' : 'register-btn-disabled'}`} type="submit"
                      // disabled={!capVal}
                      disabled={!formValid}
                    >
                      Send OTP
                    </button>
                  </Grid>
                  <div className="text-center">
                    <p className="register-verify mt-4">
                      Already Registered & Verification Pending ?
                      <Link className="c-blue ml-2" onClick={handleClickOpen}>
                        Click Here
                      </Link>
                    </p>
                  </div>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'sm'}
        open={open}
      >
        <VerifyPage handleClose={handleClose} path='signup' />
      </Dialog>
      <Delegatefreepass />
    </div>
  );
};

export default Signup;
